import { destroy, get, post } from '.'

export const Integrations = {
	upsertIntegration: (id, {
		integrationId,
		type,
		credentials,
		pickUpLocationId,
		enabled,
		isInventorySyncEnabled,
		isMerchantPriceSyncEnabled,
		inventorySyncWarehouses,
		merchantId,
		pollInterval
	} = {}) =>
		post(`/integration/${id}/config`, {
			id: integrationId,
			type,
			credentials,
			pickUpLocationId,
			enabled,
			isInventorySyncEnabled,
			isMerchantPriceSyncEnabled,
			inventorySyncWarehouses,
			merchantId,
			pollInterval
		}),
	fetchIntegrations: (id) =>
		get(`/integration/${id}/config`),
	deleteIntegration: (integrationId) => destroy(`/integration/config/${integrationId}`),
	syncWooCommerceProducts: (integrationId) => post(`/integration/woocommerce/${integrationId}/products`),
	syncDarazProducts: (integrationId) => post(`/integration/daraz/${integrationId}/products`),
	syncMagentoProducts: (integrationId) => post(`/integration/magento/${integrationId}/products`),
	doesShopExist: (shop) => get('/integration/shopify/shop', {
		params: {
			shop
		}
	}),
	authenticateDaraz: (code) => get('/integration/daraz/auth', {
		params: {
			code
		}
	})
}
