import { Button, Checkbox, Form, Input, Radio, Select, Switch, Tabs, Tooltip, notification } from 'antd'
import { LocationTypes, claimsRoles, integrationTypes } from '../../utils/constants'
import CopyToClipboard from '../copy-to-clipboard'
import { QuestionCircleOutlined, CheckOutlined } from '@ant-design/icons'
import styles from './StoreIntegrations.module.css'
import TextInput from '../text-input'
import { useEffect, useState } from 'react'
import { getSubdomain } from '../../config'
import { Integrations } from '../../services/api/integrations'
import { useSelector } from 'react-redux'
import { convertEnumToString, isNotEmpty } from '../../utils'
import { Locations } from '../../services/api/locations'
import { Tasks } from '../../services/api/firebase'

const StoreIntegrations = ({
	isEditable = false,
	companyId,
	apiKey
}) => {
	const { role } = useSelector(state => state.authReducer)
	const [isLoadingIntegration, setIsLoadingIntegration] = useState(false)
	const [isSyncingProducts, setIsSyncingProducts] = useState(false)
	const [isTaskSyncingProducts, setIsTaskSyncingProducts] = useState(false)
	const [integrations, setIntegrations] = useState([])
	const [selectedIntegration, setSelectedIntegration] = useState(integrationTypes.WOOCOMMERCE)
	const [pickUpLocations, setPickUpLocations] = useState([])
	const [wooCommerceIntegrations, setWooCommerceIntegrations] = useState([])
	const [shop, setShopName] = useState('')
	const [shopifyPickUpLocation, setShopifyPickUpLocation] = useState()
	const [isShopifyEnabled, setIsShopifyEnabled] = useState(true)
	const [darazIntegration, setDarazIntegration] = useState()
	const [isDarazEnabled, setIsDarazEnabled] = useState(true)
	const [isDarazIntegrationEdited, setIsDarazIntegrationEdited] = useState(false)
	const [darazPickUpLocation, setDarazPickUpLocation] = useState()
	const [isMagentoEnabled, setIsMagentoEnabled] = useState(true)
	const [magentoUrl, setMagentoUrl] = useState()
	const [magentoConsumerKey, setMagentoConsumerKey] = useState()
	const [magentoConsumerSecret, setMagentoConsumerSecret] = useState()
	const [magentoAccessToken, setMagentoAccessToken] = useState()
	const [magentoAccessTokenSecret, setMagentoAccessTokenSecret] = useState()
	const [magentoPickUpLocation, setMagentoPickUpLocation] = useState()
	const [magentoIntegration, setMagentoIntegration] = useState()

	useEffect(() => {
		if (role) {
			fetchPickUpLocations()
		}
	}, [role])

	useEffect(() => {
		if (companyId) {
			fetchIntegrations(companyId)
		}
	}, [companyId])

	useEffect(() => {
		if (!companyId) {
			return
		}
		const unsubscribeTasks = Tasks.listenToTasks(
			companyId,
			'bulk-upload-integrated-products',
			task => {
				if (task?.loadState === 'LOADING') {
					setIsTaskSyncingProducts(true)
				}
			},
			undefined,
			() => {
				setIsTaskSyncingProducts(false)
				notification.success({
					message: 'WooCommerce Products Synced',
					description: 'WooCommerce products have finished syncing.',
					placement: 'bottomLeft'
				})
			}
		)
		return () => {
			unsubscribeTasks()
		}
	}, [companyId])

	useEffect(() => {
		const shopifyIntegration = integrations.find(integration => integration.type === integrationTypes.SHOPIFY)
		if (shopifyIntegration && shopifyIntegration.credentials) {
			setShopName(shopifyIntegration.credentials.shop.replace('.myshopify.com', ''))
			const {
				pickUpLocation,
				enabled
			} = shopifyIntegration
			if (pickUpLocation) {
				setShopifyPickUpLocation(pickUpLocation.id)
			}
			setIsShopifyEnabled(enabled)
		} else {
			setIsShopifyEnabled(true)
		}
		const woocommerceIntegrations = integrations
			.filter(integration => integration.type === integrationTypes.WOOCOMMERCE)
			.map(integration => {
				let warehouses = integration.warehouses.map(warehouse => warehouse.id)
				if (!warehouses.length) {
					warehouses.push(integration.pickUpLocationId)
				}
				return {
					id: integration.id,
					consumerKey: integration.credentials.key,
					consumerSecret: integration.credentials.secret,
					wordpressUrl: integration.credentials.url,
					pickUpLocationId: integration.pickUpLocationId,
					enabled: integration.enabled,
					isInventorySyncEnabled: integration.isInventorySyncEnabled,
					isMerchantPriceSyncEnabled: integration.isMerchantPriceSyncEnabled,
					inventorySyncWarehouses: warehouses,
					merchantId: integration.merchantId,
					pollInterval: integration.pollInterval
				}
			})
		setWooCommerceIntegrations(woocommerceIntegrations)
		const darazIntegration = integrations.find(integration => integration.type === integrationTypes.DARAZ)
		if (darazIntegration) {
			const {
				pickUpLocation,
				enabled
			} = darazIntegration
			if (pickUpLocation) {
				setDarazPickUpLocation(pickUpLocation.id)
			}
			setIsDarazEnabled(enabled)
			setDarazIntegration(darazIntegration)
		}
		const magentoIntegration = integrations.find(integration => integration.type === integrationTypes.MAGENTO)
		if (magentoIntegration) {
			const {
				pickUpLocation,
				enabled,
				credentials
			} = magentoIntegration
			if (pickUpLocation) {
				setMagentoPickUpLocation(pickUpLocation.id)
			}
			setIsMagentoEnabled(enabled)
			setMagentoUrl(credentials.url)
			setMagentoConsumerKey(credentials.key)
			setMagentoConsumerSecret(credentials.secret)
			setMagentoAccessToken(credentials.accessToken)
			setMagentoAccessTokenSecret(credentials.accessTokenSecret)
			setMagentoIntegration(magentoIntegration)
		} else {
			setIsMagentoEnabled(true)
		}
	}, [integrations])

	const fetchPickUpLocations = async () => {
		const { data } = await Locations.index({
			type: LocationTypes.WAREHOUSE,
			page: -1,
			companyId: role === claimsRoles.ADMIN ? companyId : undefined
		})
		const locations = data.results
		setPickUpLocations(locations)
	}

	const updateIntegration = async () => {
		try {
			let credentials = {}
			let pickUpLocationId = null
			let enabled = true
			switch (selectedIntegration) {
				case integrationTypes.SHOPIFY: {
					if (!shop) {
						return
					}
					credentials.shop = `${shop}.myshopify.com`
					pickUpLocationId = shopifyPickUpLocation || null
					enabled = isShopifyEnabled
					break
				}
				case integrationTypes.DARAZ: {
					credentials = darazIntegration.credentials
					pickUpLocationId = darazPickUpLocation || null
					enabled = isDarazEnabled
					setIsDarazIntegrationEdited(false)
					break
				}
				case integrationTypes.MAGENTO: {
					credentials = {
						url: magentoUrl,
						key: magentoConsumerKey,
						secret: magentoConsumerSecret,
						accessToken: magentoAccessToken,
						accessTokenSecret: magentoAccessTokenSecret
					}
					pickUpLocationId = magentoPickUpLocation || null
					enabled = isMagentoEnabled
					break
				}
			}
			setIsLoadingIntegration(true)
			const { data } = await Integrations.upsertIntegration(companyId, {
				type: selectedIntegration,
				credentials,
				pickUpLocationId,
				enabled
			})
			if (data.type === integrationTypes.MAGENTO) {
				setMagentoIntegration(data)
			}
			notification.success({
				message: 'Updated Integration',
				description: `${convertEnumToString(selectedIntegration.toLowerCase())} integration updated.`,
				placement: 'bottomLeft'
			})
		} catch (e) {
			notification.error({
				message: 'Unable to Update Integration',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingIntegration(false)
		}
	}

	const onUpdateWooCommerceIntegration = async (index) => {
		try {
			if (!isNotEmpty(apiKey)) {
				throw new Error('Generate an API key first.')
			}
			const integration = wooCommerceIntegrations[index]
			const {
				consumerKey,
				consumerSecret,
				wordpressUrl,
				pickUpLocationId,
				enabled,
				isInventorySyncEnabled,
				isMerchantPriceSyncEnabled,
				inventorySyncWarehouses,
				id,
				merchantId,
				pollInterval
			} = integration
			const credentials = {}
			credentials.access = true
			credentials.key = consumerKey
			credentials.secret = consumerSecret
			credentials.url = wordpressUrl
			setIsLoadingIntegration(true)
			const { data } = await Integrations.upsertIntegration(companyId, {
				integrationId: id,
				type: selectedIntegration,
				credentials,
				pickUpLocationId: pickUpLocationId ? pickUpLocationId : null,
				enabled,
				isInventorySyncEnabled,
				isMerchantPriceSyncEnabled,
				inventorySyncWarehouses,
				merchantId,
				pollInterval
			})
			const updatedIntegrations = [...wooCommerceIntegrations]
			updatedIntegrations[index].id = data.id
			setWooCommerceIntegrations(updatedIntegrations)
			notification.success({
				message: 'Updated WooCommerce Integration',
				description: `${convertEnumToString(selectedIntegration.toLowerCase())} integration updated.`,
				placement: 'bottomLeft'
			})
		} catch (e) {
			notification.error({
				message: 'Unable to Update WooCommerce Integration',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingIntegration(false)
		}
	}

	const onRemoveWooCommerceIntegration = async (index) => {
		try {
			const integration = wooCommerceIntegrations[index]
			setIsLoadingIntegration(true)
			if (integration.id) {
				await Integrations.deleteIntegration(integration.id)
			}
			setWooCommerceIntegrations(wooCommerceIntegrations.filter((_, i) => i !== index))
		} catch (e) {
			notification.error({
				message: 'Unable to Remove WooCommerce Integration',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingIntegration(false)
		}
	}

	const onSyncMagentoProducts = async (integrationId) => {
		try {
			setIsSyncingProducts(true)
			if (integrationId) {
				await Integrations.syncMagentoProducts(integrationId)
				notification.success({
					message: 'Magento Products Synced',
					description: 'Magento products have been successfully synced!',
					placement: 'bottomLeft'
				})
			}
		} catch (e) {
			notification.error({
				message: 'Unable to Sync Magento Products',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsSyncingProducts(false)
		}
	}

	const onSyncWooCommerceProducts = async (integrationId) => {
		try {
			setIsSyncingProducts(true)
			if (integrationId) {
				await Integrations.syncWooCommerceProducts(integrationId)
				notification.info({
					message: 'WooCommerce Product Sync Initiated',
					description: 'WooCommerce products are being synced...',
					placement: 'bottomLeft'
				})
			}
		} catch (e) {
			notification.error({
				message: 'Unable to Sync WooCommerce Products',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsSyncingProducts(false)
		}
	}

	const onSyncDarazProducts = async () => {
		try {
			setIsSyncingProducts(true)
			await Integrations.syncDarazProducts(darazIntegration.id)
			notification.success({
				message: 'Daraz Products Synced',
				description: 'Daraz products have been successfully synced!',
				placement: 'bottomLeft'
			})
		} catch (e) {
			notification.error({
				message: 'Unable to Sync Daraz Products',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsSyncingProducts(false)
		}
	}

	const fetchIntegrations = async (id) => {
		try {
			setIsLoadingIntegration(true)
			const { data } = await Integrations.fetchIntegrations(id)
			setIntegrations(data)
		} catch (e) {
			notification.error({
				message: 'Unable to Fetch Integrations',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingIntegration(false)
		}
	}

	const onAddWooCommerceIntegration = () => {
		setWooCommerceIntegrations((integrations) => [
			...integrations,
			{
				consumerKey: '',
				consumerSecret: '',
				wordpressUrl: '',
				pickUpLocation: undefined,
				enabled: true,
				isInventorySyncEnabled: false,
				isMerchantPriceSyncEnabled: false,
				pollInterval: null
			}
		])
	}

	const initiateDarazAuth = async () => {
		try {
			setIsLoadingIntegration(true)
			await Integrations.upsertIntegration(companyId, {
				type: integrationTypes.DARAZ,
				credentials: { enabled: true },
				pickUpLocationId: darazPickUpLocation,
				enabled: true
			})
			window.location = getDarazAuthUrl()
		} catch (e) {
			notification.error({
				message: 'Unable to Update Integration',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingIntegration(false)
		}
	}

	const getDarazAuthUrl = () => {
		const callbackUrl = `https://${getSubdomain()}app.nuport.io/daraz`
		const url = `https://api.daraz.com.bd/oauth/authorize?response_type=code&force_auth=true&client_id=${process.env.NEXT_PUBLIC_DARAZ_CLIENT_ID}&redirect_uri=${callbackUrl}`
		return url
	}

	return (
		<Tabs
			activeKey={selectedIntegration}
			onChange={value => setSelectedIntegration(value)}
			size='small'
		>
			<Tabs.TabPane
				key={integrationTypes.WOOCOMMERCE}
				tab={
					<div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
						<img src='/img/woocommerce.svg' />
						<div style={{ fontSize: 12, color: 'black' }}>WooCommerce</div>
					</div>
				}
			>
				<Form.Item
					className={styles.smallSettingsContainer}
					label={
						<div className={styles.formHeader}>
							<div style={{ fontWeight: 'bold' }}>Integration(s)</div>
						</div>
					}
				>
					{
						wooCommerceIntegrations.map((integration, index) => {
							return (
								<Form.Item
									key={index}
									className={styles.smallSettingsContainer}
									label={
										<div className={styles.formHeader}>
											Webhook Configuration
											<Tooltip title='These fields should be used to setup an integration between Woocommerce and Nuport. Order/Product creations in Woocommerce should propagate to the Nuport system when setup correctly.'>
												<QuestionCircleOutlined />
											</Tooltip>
											<Switch
												checkedChildren='Enabled'
												unCheckedChildren='Disabled'
												checked={integration.enabled}
												style={{ marginLeft: 12 }}
												onChange={checked => {
													if (!isEditable) {
														return
													}
													const updatedIntegrations = [...wooCommerceIntegrations]
													updatedIntegrations[index].enabled = checked
													setWooCommerceIntegrations(updatedIntegrations)
												}}
											/>
										</div>
									}
								>
									{
										isEditable ?
											<Button
												className='company-button'
												loading={isLoadingIntegration || isSyncingProducts || isTaskSyncingProducts}
												style={{ height: 32, fontSize: 12, marginTop: 12 }}
												disabled={!integration.id}
												onClick={() => onSyncWooCommerceProducts(integration.id)}
											>
												{isSyncingProducts ? 'Syncing products, please wait...' : 'Sync Products'}
											</Button> : null
									}
									{
										isEditable ?
											<div
												style={{ marginTop: 24, marginBottom: 24 }}
											>
												<div className={styles.formHeader}>
													Sync Inventory
													<Tooltip title='Enable/Disable sync inventory. Enabling this will sync the selected warehouse inventory(s) aggregated data with the corresponding product stock in WooCommerce.'>
														<QuestionCircleOutlined />
													</Tooltip>
													<Switch
														checkedChildren='Enabled'
														unCheckedChildren='Disabled'
														checked={integration.isInventorySyncEnabled}
														style={{ marginLeft: 12 }}
														onChange={checked => {
															if (!isEditable) {
																return
															}
															const updatedIntegrations = [...wooCommerceIntegrations]
															updatedIntegrations[index].isInventorySyncEnabled = checked
															setWooCommerceIntegrations(updatedIntegrations)
														}}
													/>
												</div>
												<Select
													style={{ width: 490, marginTop: 12 }}
													className='company-select'
													mode='multiple'
													value={integration.inventorySyncWarehouses || []}
													disabled={!integration.isInventorySyncEnabled}
													onChange={values => {
														const currentValues = integration.inventorySyncWarehouses || []
														let newValues = [...values]
														if (!isEditable) {
															return
														}
														if (integration.pickUpLocationId && !newValues.includes(integration.pickUpLocationId)) {
															return
														}
														if (newValues.includes('all')) {
															if (currentValues.length === pickUpLocations.length) {
																if (integration.pickUpLocationId) {
																	newValues = [integration.pickUpLocationId]
																}
															} else {
																newValues = pickUpLocations.map(location => location.id)
															}
														}
														const updatedIntegrations = [...wooCommerceIntegrations]
														updatedIntegrations[index].inventorySyncWarehouses = newValues.filter(value => value !== 'all')
														setWooCommerceIntegrations(updatedIntegrations)
													}}
													showSearch
													optionFilterProp='children'
													filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input?.toLowerCase())}
													placeholder='Pick Up Location'
												>
													<Select.Option value='all'>
														<div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
															<b>Select All</b>
															{
																integration.inventorySyncWarehouses?.length === pickUpLocations.length &&
                                                                <CheckOutlined style={{ marginLeft: 'auto' }} />
															}
														</div>
													</Select.Option>
													{
														pickUpLocations.map(location => {
															return (
																<Select.Option key={location.id} value={location.id}>
																	{location.label || location.address}
																</Select.Option>
															)
														})
													}
												</Select>
											</div> : null
									}
									<div className={styles.smallFormHeader} style={{ marginTop: 12, display: 'flex', gap: 4, alignItems: 'center' }}>
														Delivery URL
										<Tooltip title='Paste this URL into your WooCommerce settings for Nuport to receive the relevant webhook events for: "Product Created", "Product Updated", and "Order Created".'>
											<QuestionCircleOutlined />
										</Tooltip>
									</div>
									<div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
										<Input
											value={integration.id ? `https://${getSubdomain()}api.nuport.io/integration/woocommerce/${integration.id}` : ''}
											style={{ width: 800 }}
											className='company-input'
											placeholder='Save integration details to get the webhook delivery URL.'
										/>
										{
											integration.id &&
															<CopyToClipboard text={`https://${getSubdomain()}api.nuport.io/integration/woocommerce/${integration.id}`} />
										}
									</div>
									<div className={styles.smallFormHeader} style={{ marginTop: 12 }}>Secret</div>
									<div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
										<Input.Password
											style={{ width: 490 }}
											className='company-password'
											disabled={apiKey === null || undefined}
											value={apiKey}
											placeholder='Secret'
										/>
										<CopyToClipboard text={apiKey} />
									</div>
									<div className={styles.formHeader} style={{ marginTop: 24 }}>
										REST API Configuration
										<Tooltip title='These fields are used to allow Nuport to communicate directly with WooCommerce.'>
											<QuestionCircleOutlined />
										</Tooltip>
									</div>
									<div className={styles.smallFormHeader} style={{ marginTop: 12 }}>Wordpress URL</div>
									<Input
										value={integration.wordpressUrl}
										placeholder='Wordpress URL (https://www.example.com)'
										onChange={({ target: { value } }) => {
											const updatedIntegrations = [...wooCommerceIntegrations]
											updatedIntegrations[index].wordpressUrl = value
											setWooCommerceIntegrations(updatedIntegrations)
										}}
										style={{ width: 490 }}
										className='company-input'
									/>
									<div className={styles.smallFormHeader} style={{ marginTop: 12 }}>Consumer Key</div>
									<Input
										value={integration.consumerKey}
										placeholder='Consumer Key (ck_xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx)'
										onChange={({ target: { value } }) => {
											const updatedIntegrations = [...wooCommerceIntegrations]
											updatedIntegrations[index].consumerKey = value
											setWooCommerceIntegrations(updatedIntegrations)
										}}
										style={{ width: 490 }}
										className='company-input'
									/>
									<div className={styles.smallFormHeader} style={{ marginTop: 12 }}>Consumer Secret</div>
									<Input
										value={integration.consumerSecret}
										placeholder='Consumer Secret (cs_xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx)'
										onChange={({ target: { value } }) => {
											const updatedIntegrations = [...wooCommerceIntegrations]
											updatedIntegrations[index].consumerSecret = value
											setWooCommerceIntegrations(updatedIntegrations)
										}}
										style={{ width: 490 }}
										className='company-input'
									/>
									<div className={styles.smallFormHeader} style={{ marginTop: 12 }}>Poll Interval
										<Tooltip title='Enable if you want Nuport to periodically check for new orders.'>
											<QuestionCircleOutlined style={{ marginLeft: 8 }} />
										</Tooltip>
									</div>
									<div style={{ display: 'flex', gap: 24 }}>
										<Checkbox
											checked={integration.pollInterval !== null}
											value={'pollInterval'}
											onChange={() => {
												const updatedIntegrations = [...wooCommerceIntegrations]
												if (updatedIntegrations[index].pollInterval !== null) {
													updatedIntegrations[index].pollInterval = null
												} else {
													updatedIntegrations[index].pollInterval = 15
												}
												setWooCommerceIntegrations(updatedIntegrations)
											}}
										>
											<div style={{ fontSize: 14 }}>
                                        Poll Orders
											</div>
										</Checkbox>
										<Radio.Group
											disabled={integration.pollInterval === null}
											value={integration.pollInterval}
											onChange={e => {
												const updatedIntegrations = [...wooCommerceIntegrations]
												updatedIntegrations[index].pollInterval = e.target.value
												setWooCommerceIntegrations(updatedIntegrations)
											}}
										>
											<Radio
												value={5}
											>
                                                5 minutes
											</Radio>
											<Radio
												value={10}
											>
                                                10 minutes
											</Radio>
											<Radio
												value={15}
											>
                                                15 minutes
											</Radio>
										</Radio.Group>
									</div>
									<div className={styles.smallFormHeader} style={{ marginTop: 12 }}>Pick Up Address</div>
									<Select
										style={{ width: 490 }}
										className='company-select'
										value={integration.pickUpLocationId}
										options={pickUpLocations.map(location => ({ value: location.id, label: location.label || location.address }))}
										onChange={value => {
											const updatedIntegrations = [...wooCommerceIntegrations]
											const inventorySyncWarehouses = updatedIntegrations[index].inventorySyncWarehouses
											if (inventorySyncWarehouses) {
												if (value) {
													if (!inventorySyncWarehouses.includes(value)) {
														inventorySyncWarehouses.push(value)
													}
												} else {
													inventorySyncWarehouses.splice(inventorySyncWarehouses.indexOf(updatedIntegrations[index].pickUpLocationId), 1)
												}
												updatedIntegrations[index].inventorySyncWarehouses = inventorySyncWarehouses
											}

											updatedIntegrations[index].pickUpLocationId = value
											setWooCommerceIntegrations(updatedIntegrations)
										}}
										showSearch
										optionFilterProp='children'
										filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input?.toLowerCase())}
										allowClear={false}
										placeholder='Pick Up Location'
									/>
									<div style={{ display: 'flex', alignItems: 'center', gap: 24 }}>
										<div>
											<div className={styles.smallFormHeader} style={{ marginTop: 12 }}>Merchant ID (Optional)</div>
											<Input
												value={integration.merchantId}
												placeholder='Merchant ID (Optional)'
												onChange={({ target: { value } }) => {
													const updatedIntegrations = [...wooCommerceIntegrations]
													updatedIntegrations[index].merchantId = value
													setWooCommerceIntegrations(updatedIntegrations)
												}}
												style={{ width: 290 }}
												className='company-input'
											/>
										</div>
										<div style={{ height: 80.85 }}>
											<div className={styles.smallFormHeader} style={{ marginTop: 12 }}>Sync Product Price
												<Tooltip title='Enable/Disable Sync Product Price. Enabling this will sync the corresponding Product price between Nuport and WooCommerce despite the Merchant ID.'>
													<QuestionCircleOutlined style={{ marginLeft: 8 }} />
												</Tooltip>
											</div>
											<Switch
												disabled={!integration.merchantId}
												checkedChildren='Enabled'
												unCheckedChildren='Disabled'
												checked={integration.isMerchantPriceSyncEnabled}
												onChange={checked => {
													if (!isEditable) {
														return
													}
													const updatedIntegrations = [...wooCommerceIntegrations]
													updatedIntegrations[index].isMerchantPriceSyncEnabled = checked
													setWooCommerceIntegrations(updatedIntegrations)
												}}
											/>
										</div>
										{
											isEditable ?
												<div style={{ display: 'flex', marginLeft: 'auto', gap: 8 }}>
													<Button
														className='company-button'
														loading={isLoadingIntegration}
														style={{ width: 100 }}
														onClick={() => onRemoveWooCommerceIntegration(index)}
														danger={true}
													>
														Remove
													</Button>
													<Button
														className='company-button'
														loading={isLoadingIntegration}
														style={{ width: 100 }}
														onClick={() => onUpdateWooCommerceIntegration(index)}
														disabled={!integration.pickUpLocationId}
													>
														Save
													</Button>
												</div> : null
										}
									</div>
								</Form.Item>
							)
						})
					}
					<Button
						className='company-button'
						loading={isLoadingIntegration}
						style={{ marginLeft: 'auto' }}
						onClick={onAddWooCommerceIntegration}
						disabled={wooCommerceIntegrations.length > 0 && !wooCommerceIntegrations[wooCommerceIntegrations.length - 1].id}
					>
						{
							wooCommerceIntegrations.length > 0 ?
								'Add Another Integration' : 'Add Integration'
						}
					</Button>
				</Form.Item>
			</Tabs.TabPane>
			<Tabs.TabPane
				key={integrationTypes.SHOPIFY}
				tab={
					<div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
						<img src='/img/shopify.svg' />
						<div style={{ fontSize: 12, color: 'black' }}>Shopify</div>
					</div>
				}
			>
				<Form.Item
					className={styles.smallSettingsContainer}
					label={
						<div className={styles.formHeader}>
											Shop URL
							<Tooltip title='Unique shop name for your Shopify store. Order creations in Shopify should propagate to the Nuport system when setup correctly.'>
								<QuestionCircleOutlined />
							</Tooltip>
							<Switch
								checkedChildren='Enabled'
								unCheckedChildren='Disabled'
								checked={isShopifyEnabled}
								style={{ marginLeft: 24 }}
								onChange={checked => {
									if (!isEditable) {
										return
									}
									setIsShopifyEnabled(checked)
								}}
							/>
						</div>
					}
				>
					<div style={{ display: 'flex' }}>
						<TextInput
							placeholder='shopify-store-name'
							value={shop}
							onChange={e => {
								const value = e.target.value.toLowerCase()
								setShopName(value.toLowerCase())
							}}
						/>
						<div className={styles.addOn}>
							.myshopify.com
						</div>
					</div>
					<div className={styles.smallFormHeader} style={{ marginTop: 12 }}>Pick Up Address</div>
					<div style={{ display: 'flex', width: '100%' }}>
						<Select
							style={{ width: 490 }}
							className='company-select'
							value={shopifyPickUpLocation}
							options={pickUpLocations.map(location => ({ value: location.id, label: location.label || location.address }))}
							onChange={setShopifyPickUpLocation}
							showSearch
							optionFilterProp='children'
							filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input?.toLowerCase())}
							allowClear
							placeholder='Pick Up Location'
						/>
						<Button
							className='company-button'
							disabled={!shop || shop.trim().length === 0}
							loading={isLoadingIntegration}
							style={{ width: 100, marginLeft: 'auto' }}
							onClick={updateIntegration}
						>
							Save
						</Button>
					</div>
				</Form.Item>
			</Tabs.TabPane>
			<Tabs.TabPane
				key={integrationTypes.DARAZ}
				tab={
					<div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
						<img src='/img/daraz.svg' />
						<div style={{ fontSize: 12, color: 'rgba(0, 0, 0, 0.25)' }}>Daraz</div>
					</div>
				}
			>
				<Form.Item
					className={styles.smallSettingsContainer}
					label={
						darazIntegration ?
							<div className={styles.formHeader}>
								<Switch
									checkedChildren='Enabled'
									unCheckedChildren='Disabled'
									checked={isDarazEnabled}
									onChange={checked => {
										if (!isEditable) {
											return
										}
										setIsDarazIntegrationEdited(true)
										setIsDarazEnabled(checked)
									}}
								/>
							</div> :
							<div style={{ fontSize: 12 }}>Authenticate to start syncing products and orders from Daraz.</div>
					}
				>
					{
						darazIntegration ?
							<div>
								<div
									style={{ marginTop: 12 }}
									className={styles.success}
								>
									<CheckOutlined />
									Authenticated
								</div>
								{
									isEditable ?
										<Button
											className='company-button'
											loading={isLoadingIntegration || isSyncingProducts}
											style={{ height: 32, fontSize: 12, marginTop: 12 }}
											onClick={onSyncDarazProducts}
										>
											{isSyncingProducts ? 'Syncing products, please wait...' : 'Sync Products'}
										</Button> : null
								}
								<div style={{ marginTop: 12 }}>
									<div className={styles.smallFormHeader} style={{ marginTop: 12 }}>Pick Up Address</div>
									<Select
										style={{ width: 490 }}
										className='company-select'
										value={darazPickUpLocation}
										options={pickUpLocations.map(location => ({ value: location.id, label: location.label || location.address }))}
										onChange={option => {
											if (!isEditable) {
												return
											}
											setDarazPickUpLocation(option)
											setIsDarazIntegrationEdited(true)
										}}
										showSearch
										optionFilterProp='children'
										filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input?.toLowerCase())}
										placeholder='Pick Up Location'
									/>
								</div>
								<div style={{ marginTop: 12, display: 'flex' }}>
									<Button
										className='company-button'
										loading={isLoadingIntegration}
										disabled={!isDarazIntegrationEdited}
										style={{ width: 100 }}
										containerStyle={{ marginLeft: 'auto' }}
										onClick={updateIntegration}
									>
										Save
									</Button>
								</div>
							</div> :
							<div>
								<Button
									className='company-button'
									loading={isLoadingIntegration}
									style={{ marginLeft: 'auto', marginTop: 12 }}
									disabled={!darazPickUpLocation}
									onClick={initiateDarazAuth}
								>
												Authenticate
								</Button>
								<div style={{ marginTop: 12 }}>
									<div className={styles.smallFormHeader} style={{ marginTop: 12 }}>Pick Up Address</div>
									<Select
										style={{ width: 490 }}
										className='company-select'
										value={darazPickUpLocation}
										options={pickUpLocations.map(location => ({ value: location.id, label: location.label || location.address }))}
										onChange={option => {
											if (!isEditable) {
												return
											}
											setDarazPickUpLocation(option)
											setIsDarazIntegrationEdited(true)
										}}
										showSearch
										optionFilterProp='children'
										filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input?.toLowerCase())}
										placeholder='Pick Up Location'
									/>
								</div>
							</div>
					}
				</Form.Item>
			</Tabs.TabPane>
			<Tabs.TabPane
				key={integrationTypes.MAGENTO}
				tab={
					<div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
						<img src='/img/magento.svg' style={{ width: 40, height: 40 }} />
						<div style={{ fontSize: 12, color: 'black' }}>Magento</div>
					</div>
				}
			>
				<Form.Item
					className={styles.smallSettingsContainer}
					label={
						<div className={styles.formHeader}>
							<div style={{ fontWeight: 'bold' }}>Integration</div>
							<Switch
								checkedChildren='Enabled'
								unCheckedChildren='Disabled'
								checked={isMagentoEnabled}
								style={{ marginLeft: 24 }}
								onChange={checked => {
									if (!isEditable) {
										return
									}
									setIsMagentoEnabled(checked)
								}}
							/>
						</div>
					}
				>
					{
						isEditable && magentoIntegration ?
							<Button
								className='company-button'
								loading={isLoadingIntegration || isSyncingProducts}
								style={{ height: 32, fontSize: 12, marginTop: 12 }}
								disabled={!magentoIntegration.id}
								onClick={() => onSyncMagentoProducts(magentoIntegration.id)}
							>
								{isSyncingProducts ? 'Syncing products, please wait...' : 'Sync Products'}
							</Button> : null
					}
					<div className={styles.smallFormHeader} style={{ marginTop: 12 }}>Magento URL</div>
					<Input
						value={magentoUrl}
						placeholder='Magento URL (https://www.example.com)'
						onChange={({ target: { value } }) => {
							setMagentoUrl(value)
						}}
						style={{ width: 490 }}
						className='company-input'
					/>
					<div className={styles.smallFormHeader} style={{ marginTop: 12 }}>Consumer Key</div>
					<Input
						value={magentoConsumerKey}
						placeholder='Consumer Key'
						onChange={({ target: { value } }) => {
							setMagentoConsumerKey(value)
						}}
						style={{ width: 490 }}
						className='company-input'
					/>
					<div className={styles.smallFormHeader} style={{ marginTop: 12 }}>Consumer Secret</div>
					<Input
						value={magentoConsumerSecret}
						placeholder='Consumer Secret'
						onChange={({ target: { value } }) => {
							setMagentoConsumerSecret(value)
						}}
						style={{ width: 490 }}
						className='company-input'
					/>
					<div className={styles.smallFormHeader} style={{ marginTop: 12 }}>Access Token</div>
					<Input
						value={magentoAccessToken}
						placeholder='Access Token'
						onChange={({ target: { value } }) => {
							setMagentoAccessToken(value)
						}}
						style={{ width: 490 }}
						className='company-input'
					/>
					<div className={styles.smallFormHeader} style={{ marginTop: 12 }}>Access Token Secret</div>
					<Input
						value={magentoAccessTokenSecret}
						placeholder='Access Token Secret'
						onChange={({ target: { value } }) => {
							setMagentoAccessTokenSecret(value)
						}}
						style={{ width: 490 }}
						className='company-input'
					/>
					<div className={styles.smallFormHeader} style={{ marginTop: 12 }}>Pick Up Address</div>
					<div style={{ display: 'flex' }}>
						<Select
							style={{ width: 490 }}
							className='company-select'
							value={magentoPickUpLocation}
							options={pickUpLocations.map(location => ({ value: location.id, label: location.label || location.address }))}
							onChange={value => {
								setMagentoPickUpLocation(value)
							}}
							showSearch
							optionFilterProp='children'
							filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input?.toLowerCase())}
							allowClear
							placeholder='Pick Up Location'
						/>
					</div>
					{
						isEditable &&
							<Button
								className='company-button'
								disabled={!magentoUrl || !magentoConsumerKey || !magentoConsumerSecret || !magentoAccessToken || !magentoAccessTokenSecret || !magentoPickUpLocation}
								loading={isLoadingIntegration}
								style={{ width: 100, marginTop: 12 }}
								onClick={updateIntegration}
							>
							Save
							</Button>
					}
				</Form.Item>
			</Tabs.TabPane>
		</Tabs>
	)
}

export default StoreIntegrations
