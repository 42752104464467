import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { notification } from 'antd'
import CustomDeliveryAreaSelect from '../custom-delivery-area-select'
import { Locations } from '../../services/api/locations'
import { DeliveryPartners } from '../../services/api/delivery-partner'
import { PartnerType } from '../../utils/constants'

const DeliveryAreaSelectRedX = ({ deliveryPartnerId, title, error, value, onChange }) => {
	const [isLoadingDivisions, setIsLoadingDivisions] = useState(false)
	const [isLoadingDistricts, setIsLoadingDistricts] = useState(false)
	const [isLoadingAreas, setIsLoadingAreas] = useState(false)
	const [divisions, setDivisions] = useState([])
	const [districts, setDistricts] = useState([])
	const [areas, setAreas] = useState([])

	useEffect(() => {
		getDivisions()
	}, [])

	useEffect(() => {
		if (!value?.division?.value) {
			setDistricts([])
			setAreas([])
			return
		}
		getDistricts(value.division.value)
	}, [value?.division?.value])

	useEffect(() => {
		if (!value?.district?.value) {
			setAreas([])
			return
		}
		getAreas(deliveryPartnerId, value.district.value)
	}, [value?.district?.value])

	const getDivisions = async () => {
		setIsLoadingDivisions(true)
		try {
			const { data } = await Locations.divisions()
			setDivisions(data?.length ? data : [])
		} catch (e) {
			onChange({ ...value, division: null })
			setDivisions([])
			notification.error({
				message: 'Unable To Get Divisions',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingDivisions(false)
		}
	}

	const getDistricts = async division => {
		setIsLoadingDistricts(true)
		try {
			const { data } = await Locations.districts({ division, partnerType: PartnerType.REDX })
			setDistricts(data?.length ? data : [])
		} catch (e) {
			onChange({ ...value, district: null })
			setDistricts([])
			notification.error({
				message: 'Unable To Get Districts',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingDistricts(false)
		}
	}

	const getAreas = async (deliveryPartnerId, district) => {
		setIsLoadingAreas(true)
		try {
			const { data } = await DeliveryPartners.getDeliveryAreas(deliveryPartnerId, { district })
			const areaOptions = data?.map(area => ({
				...area,
				value: area.id,
				label: area.name
			})) || []
			setAreas(areaOptions)
		} catch (e) {
			onChange({ ...value, area: null })
			setAreas([])
			notification.error({
				message: 'Unable To Get Areas',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoadingAreas(false)
		}
	}

	const onDivisionChange = async (_, option) => {
		onChange({
			...value,
			division: option ? { value: option.value, label: option.label } : null,
			district: null,
			area: null
		})
	}

	const onDistrictChange = async (_, option) => {
		onChange({
			...value,
			district: option ? { value: option.value, label: option.label } : null,
			area: null
		})
	}

	const onAreaChange = (_, option) => {
		onChange({
			...value,
			area: option ? { value: option.value, label: option.label } : null
		})
	}

	return (
		<CustomDeliveryAreaSelect
			title={title}
			error={error}
			levels={[
				{
					id: 'division',
					placeholder: 'Division',
					loading: isLoadingDivisions,
					options: divisions,
					value: value?.division || null,
					onChange: onDivisionChange,
					onClear: () => onDivisionChange(),
					style: { width: '33%' }
				},
				{
					id: 'district',
					placeholder: 'District',
					loading: isLoadingDistricts,
					options: districts,
					value: value?.district || null,
					onChange: onDistrictChange,
					onClear: () => onDistrictChange(),
					style: { width: '33%' }
				},
				{
					id: 'area',
					placeholder: 'Area',
					loading: isLoadingAreas,
					options: areas,
					value: value?.area || null,
					onChange: onAreaChange,
					onClear: () => onAreaChange(),
					style: { width: '33%' }
				}
			]}
		/>
	)
}

DeliveryAreaSelectRedX.propTypes = {
	deliveryPartnerId: PropTypes.string,
	title: PropTypes.string,
	error: PropTypes.bool,
	value: PropTypes.object,
	onChange: PropTypes.func
}

DeliveryAreaSelectRedX.defaultProps = {
	deliveryPartnerId: '',
	title: 'Delivery Area',
	error: false,
	value: null,
	onChange: () => {}
}

export default DeliveryAreaSelectRedX
