import styles from './CustomDeliveryAreaSelect.module.css'
import PropTypes from 'prop-types'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Select, Tooltip } from 'antd'

const CustomDeliveryAreaSelect = ({ title, tooltip, error, levels, style }) => {
	const filterOption = (input, option) => (option?.label || '').toLowerCase().includes(input.trim().toLowerCase())

	return (
		<div
			className={`${styles.deliverySelectContainer} ${error && styles.error}`}
			style={style}
		>
			{
				title &&
				<div className={styles.titleContainer}>
					<div className={styles.title}>{title}</div>
					{
						tooltip &&
						<Tooltip title={tooltip}>
							<InfoCircleOutlined style={{ fontSize: '12px' }} />
						</Tooltip>
					}
				</div>
			}
			<div className={styles.selectContainer}>
				{
					levels.map(({ id, placeholder, disabled, options, value, onChange, onClear, loading, style }) => {
						return (
							<Select
								key={id}
								bordered={false}
								placeholder={placeholder}
								disabled={disabled}
								loading={loading}
								showSearch={true}
								allowClear={true}
								options={options}
								value={value?.value}
								onChange={onChange}
								onClear={onClear}
								filterOption={filterOption}
								style={{ minWidth: '120px', maxWidth: '240px', ...style }}
							/>
						)
					})
				}
			</div>
		</div>
	)
}

CustomDeliveryAreaSelect.propTypes = {
	title: PropTypes.string,
	tooltip: PropTypes.string,
	error: PropTypes.bool,
	levels: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string.isRequired,
		placeholder: PropTypes.string,
		disabled: PropTypes.bool,
		loading: PropTypes.bool,
		options: PropTypes.arrayOf(PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			label: PropTypes.string
		})),
		value: PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			label: PropTypes.string
		}).isRequired,
		onChange: PropTypes.func,
		onClear: PropTypes.func,
		style: PropTypes.object
	})),
	style: PropTypes.object
}

CustomDeliveryAreaSelect.defaultProps = {
	title: '',
	tooltip: '',
	error: false,
	levels: [],
	style: {}
}

export default CustomDeliveryAreaSelect
