import { getSubdomain } from '../../config'
import { get, post, patch, destroy } from './index'

export const Users = {
	index: (
		{
			page = 0,
			searchTerm,
			role,
			disabled,
			toDate,
			fromDate,
			permissionGroups,
			permissionGroupId,
			permission,
			locationIds,
			emptyLocationsIds,
			companyId,
			available
		} = {},
		abortSignal
	) =>
		get('/users', {
			params: {
				searchTerm,
				page,
				role,
				disabled,
				toDate,
				fromDate,
				permissionGroups,
				permissionGroupId,
				permission,
				locationIds,
				emptyLocationsIds,
				companyId,
				available
			},
			signal: abortSignal
		}),
	single: (id) =>
		get(`/users/${id}`),
	create: ({
		email,
		phone,
		name,
		role,
		internalId,
		permissionGroupId,
		locationIds,
		password,
		salary,
		profitPercentage
	} = {}) =>
		post('/users', {
			email,
			phone,
			name,
			role,
			internalId,
			permissionGroupId,
			locationIds,
			password,
			salary,
			profitPercentage
		}),
	signUp: ({
		name,
		email,
		phone,
		website,
		companyName,
		companyTag,
		address,
		latitude,
		longitude,
		password,
		idToken,
		team,
		industry,
		industryType,
		subscriptionTier,
		subscriptionPeriod,
		isTrialSubscription,
		recaptchaToken
	}) =>
		post('/users/sign-up', {
			name,
			email,
			phone,
			website,
			companyName,
			companyTag,
			address,
			latitude,
			longitude,
			password,
			idToken,
			team,
			industry,
			industryType,
			subscriptionTier,
			subscriptionPeriod,
			isTrialSubscription,
			recaptchaToken
		}),
	update: (id, {
		disabled,
		name,
		phone,
		email,
		internalId,
		role,
		locationIds,
		vehicleTypes,
		defaultShippingDateEnabled,
		metadata,
		salary,
		profitPercentage
	} = {}) =>
		patch(`/users/${id}`, {
			disabled,
			name,
			phone,
			email,
			internalId,
			role,
			locationIds,
			vehicleTypes,
			defaultShippingDateEnabled,
			metadata,
			salary,
			profitPercentage
		}),
	remove: (id) =>
		destroy(`/users/${id}`),
	fetchTeams: () => get('/users/teams'),
	fetchIndustries: () => get('/users/industries'),
	getEcommerceQuestionnaire: ({ country }) =>
		get('/users/ecommerce-questionnaire', { params: { country } }),
	fetchIntegrations: () => get('/users/integrations'),
	exists: ({ email, phone } = {}) => get('/users/exists', {
		params: {
			email,
			phone
		}
	}),
	createCustomToken: (id) => post(`/users/token${id ? `/${id}` : ''}`),
	forgotPassword: (email) => {
		let baseURL = `https://${getSubdomain()}forgot-password.nuport.io`
		if (process.env.NEXT_PUBLIC_FIRESTORE_EMULATOR_HOST) {
			baseURL = 'http://localhost:5001/nuport-dev/us-central1/forgotPasswordGen2'
		}
		return get('', {
			baseURL,
			params: {
				email
			}
		})
	},
	getGoogleAuthTokens: (code) => post('users/auth/google', { code })
}
