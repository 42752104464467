import { Products } from '../../services/api/products'
import { Inventory } from '../../services/api/inventory'
import { InventoryProductItems } from '../../services/api/firebase'
import {
	ADD_PRODUCT_SUCCESS,
	FETCH_INVENTORY_SUCCESS,
	FETCH_PRODUCTS_SUCCESS,
	FETCH_PRODUCT_CATEGORIES_SUCCESS,
	FETCH_PRODUCT_DETAILS_SUCCESS,
	FETCH_PRODUCT_METRICS_SUCCESS,
	FETCH_WAREHOUSE_DETAILS_SUCCESS,
	UPDATE_PRODUCT_SUCCESS,
	FETCH_INVENTORY_PRODUCTS_SUCCESS,
	FETCH_INVENTORY_SKUS_SUCCESS,
	UPDATE_INVENTORY_PRODUCT_ITEM_SUCCESS,
	ADD_INVENTORY_PRODUCT_ITEM_SUCCESS,
	FETCH_INVENTORY_FEED_ITEMS_SUCCESS,
	ADD_INVENTORY_FEED_ITEM,
	FETCH_INVENTORY_ON_HAND_STOCK_SUCCESS,
	FETCH_INVENTORY_STOCK_MONITORING_SUCCESS,
	FETCH_MORE_INVENTORY_FEED_ITEMS_SUCCESS,
	FETCH_INVENTORY_ORDER_MOVEMENT_SUCCESS,
	FETCH_BLOCKED_INVENTORY_SUCCESS,
	BLOCK_INVENTORY_ITEMS_SUCCESS,
	FETCH_INVENTORY_LOGS_SUCCESS,
	FETCH_INVENTORY_BATCH_ITEMS_SUCCESS
} from './action-types'

export function fetchProducts ({
	searchTerm,
	page = 0,
	type,
	category,
	subCategory1,
	subCategory2,
	subCategory3,
	subCategory4,
	subCategory5,
	deleted,
	all,
	vendorId,
	skipEmptyVendorMapping,
	excludeBase,
	excludeVariants,
	excludeSimple,
	attributes
} = {}) {
	return async (dispatch) => {
		const response = await Products.index({
			searchTerm,
			page,
			type,
			category,
			subCategory1,
			subCategory2,
			subCategory3,
			subCategory4,
			subCategory5,
			deleted,
			all,
			vendorId,
			skipEmptyVendorMapping,
			excludeBase,
			excludeVariants,
			excludeSimple,
			attributes
		})
		dispatch({ type: FETCH_PRODUCTS_SUCCESS, payload: response.data })
	}
}

export function addProduct (params) {
	return async (dispatch) => {
		const response = await Products.create(params)
		dispatch({ type: ADD_PRODUCT_SUCCESS, payload: response.data })
	}
}

export function updateProduct (id, params) {
	return async (dispatch) => {
		const response = await Products.update(id, params)
		dispatch({ type: UPDATE_PRODUCT_SUCCESS, payload: response.data })
	}
}

export function fetchProductCategories (params = {}) {
	return async dispatch => {
		const response = await Products.fetchCategories(params)
		dispatch({ type: FETCH_PRODUCT_CATEGORIES_SUCCESS, payload: { data: response.data, industry: params.industry } })
		return response
	}
}

export function fetchProductDetails (id) {
	return async (dispatch) => {
		const response = await Products.single(id, {
			inventory: true,
			packages: true,
			variants: true
		})
		dispatch({ type: FETCH_PRODUCT_DETAILS_SUCCESS, payload: { data: response.data, id } })
	}
}

export function fetchInventory ({
	page = 0,
	searchTerm,
	locationId,
	updatedFrom,
	updatedTo,
	minQuantity,
	maxQuantity
} = {}) {
	return async (dispatch) => {
		const response = await Inventory.indexInventory({
			page,
			searchTerm,
			locationId,
			updatedFrom,
			updatedTo,
			minQuantity,
			maxQuantity
		})
		dispatch({ type: FETCH_INVENTORY_SUCCESS, payload: response.data })
	}
}

export function fetchInventoryProducts ({
	page = 0,
	searchTerm,
	locationId,
	updatedFrom,
	updatedTo,
	isPalleted,
	isInStock,
	isOrdered,
	skus,
	createdFrom,
	createdTo,
	blocked
} = {}) {
	return async (dispatch) => {
		const response = await Inventory.indexInventoryProducts({
			page,
			searchTerm,
			locationId,
			updatedFrom,
			updatedTo,
			isPalleted,
			isInStock,
			isOrdered,
			skus,
			createdFrom,
			createdTo,
			blocked
		})
		dispatch({ type: FETCH_INVENTORY_PRODUCTS_SUCCESS, payload: { data: response.data, isInStock } })
	}
}

export function fetchInventoryOnHandStock (
	{
		page = 0,
		searchTerm,
		updatedFrom,
		updatedTo,
		skus,
		createdFrom,
		createdTo,
		shifts,
		packCodes,
		machineCodes,
		shelfLives,
		facilities,
		sortInTransitQuantity,
		sortOnHandStockQuantity
	} = {}
) {
	return async (dispatch) => {
		const response = await Inventory.indexInventoryOnHandStock({
			page,
			searchTerm,
			updatedFrom,
			updatedTo,
			skus,
			createdFrom,
			createdTo,
			shifts,
			packCodes,
			machineCodes,
			shelfLives,
			facilities,
			sortInTransitQuantity,
			sortOnHandStockQuantity
		})
		dispatch({ type: FETCH_INVENTORY_ON_HAND_STOCK_SUCCESS, payload: response.data })
	}
}

export function fetchInventorySkus ({
	page = 0,
	type,
	searchTerm,
	locationId,
	includeInventoryBatchItems,
	category,
	subCategory1,
	subCategory2,
	subCategory3,
	subCategory4,
	subCategory5,
	attributes
} = {}) {
	return async (dispatch) => {
		const response = await Products.index({
			page,
			type,
			searchTerm,
			locationId,
			inventory: true,
			includeInventoryBatchItems,
			category,
			subCategory1,
			subCategory2,
			subCategory3,
			subCategory4,
			subCategory5,
			attributes
		})
		dispatch({ type: FETCH_INVENTORY_SKUS_SUCCESS, payload: response.data })
	}
}

export function fetchProductMetrics (type) {
	return async (dispatch) => {
		const response = await Products.getMetrics(type)
		dispatch({ type: FETCH_PRODUCT_METRICS_SUCCESS, payload: response.data })
	}
}

export function fetchInventoryStockMonitoringData (params) {
	return async (dispatch) => {
		const response = await Inventory.fetchInventoryStockMonitoring(params)
		dispatch({ type: FETCH_INVENTORY_STOCK_MONITORING_SUCCESS, payload: response.data })
	}
}

export function fetchInventoryOrderMovement (params) {
	return async (dispatch) => {
		const response = await Inventory.indexInventoryOrderMovement(params)
		dispatch({ type: FETCH_INVENTORY_ORDER_MOVEMENT_SUCCESS, payload: response.data })
	}
}

export function fetchWarehouseDetails (id) {
	return async (dispatch) => {
		const response = await Inventory.singleWarehouse(id)
		dispatch({ type: FETCH_WAREHOUSE_DETAILS_SUCCESS, payload: { data: response.data, id } })
	}
}

export function updateInventoryProductItem (payload) {
	return (dispatch) => {
		dispatch({ type: UPDATE_INVENTORY_PRODUCT_ITEM_SUCCESS, payload })
	}
}

export function addInventoryProductItem (payload) {
	return (dispatch) => {
		dispatch({ type: ADD_INVENTORY_PRODUCT_ITEM_SUCCESS, payload })
	}
}

export function fetchInventoryProductItemHistory (cid) {
	return async (dispatch) => {
		const inventoryProductHistoryItems = await InventoryProductItems.fetchInventoryProductItemHistoryOnce(cid)
		dispatch({ type: FETCH_INVENTORY_FEED_ITEMS_SUCCESS, payload: inventoryProductHistoryItems })
	}
}

export function fetchMoreInventoryProductItemHistory (cid, lastDocumentId) {
	return async (dispatch) => {
		const inventoryProductHistoryItems = await InventoryProductItems.fetchMoreInventoryProductItemHistory(cid, lastDocumentId)
		dispatch({ type: FETCH_MORE_INVENTORY_FEED_ITEMS_SUCCESS, payload: inventoryProductHistoryItems })
	}
}

export function addInventoryProductItemHistory (payload) {
	return async (dispatch) => {
		dispatch({ type: ADD_INVENTORY_FEED_ITEM, payload })
	}
}

export function fetchBlockedInventoryProducts ({
	page = 0,
	...searchTerms
} = {}) {
	return async (dispatch) => {
		const response = await Inventory.indexInventoryProducts({
			page,
			blocked: true,
			...searchTerms
		})
		dispatch({ type: FETCH_BLOCKED_INVENTORY_SUCCESS, payload: { data: response.data } })
	}
}

export function blockInventoryProducts (
	blocked,
	serialNumbers = []
) {
	return async (dispatch) => {
		const response = await Inventory.blockInventoryProducts({
			blocked,
			serialNumbers
		})
		dispatch({ type: BLOCK_INVENTORY_ITEMS_SUCCESS, payload: { data: response.data } })
	}
}

export function fetchInventoryLogs ({
	page = 0,
	searchTerm,
	locationId,
	startDate,
	endDate,
	updatedBy,
	productType,
	quantityChangeType
} = {}) {
	return async (dispatch) => {
		const response = await Inventory.fetchInventoryLogs({
			page,
			searchTerm,
			locationId,
			startDate,
			endDate,
			updatedBy,
			productType,
			quantityChangeType
		})
		dispatch({ type: FETCH_INVENTORY_LOGS_SUCCESS, payload: response.data })
	}
}

export function fetchInventoryBatchItems ({
	page = 0,
	searchTerm,
	excludeShortageQuantity,
	locationId,
	orderByField,
	sortOrder
} = {}) {
	return async (dispatch) => {
		const response = await Inventory.fetchInventoryBatchItems({
			page,
			searchTerm,
			excludeShortageQuantity,
			locationId,
			orderByField,
			sortOrder
		})
		dispatch({ type: FETCH_INVENTORY_BATCH_ITEMS_SUCCESS, payload: response.data })
	}
}
