import styles from './DeliveryPlansDetails.module.css'
import { Button, Spin, Layout, Row, Col, Tooltip, Dropdown, Badge, Menu, notification, Modal as AntModal, Empty, Popover, Modal } from 'antd'
import { useRouter } from 'next/router'
import { useEffect, useState, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import Page from '../../components/page'
import {
	assignVehicleToDeliveryPlanItem,
	fetchDeliveryPlanDetails,
	updateDeliveryPlan,
	updateDeliveryPlanItem,
	updateDeliveryPlanItemStatus,
	updateDeliveryPlanRoutes
} from '../../store/delivery-plans/actions'
import { getFormattedCurrency, convertEnumToString, getDistanceFromLatLonInMeters } from '../../utils'
import Table from '../../components/table'
import moment from 'moment'
import 'moment-timezone'
import Link from 'next/link'
import { DownOutlined, FilePdfOutlined, PlusOutlined, DeleteOutlined, PrinterOutlined, MoreOutlined, InfoCircleFilled } from '@ant-design/icons'
import { Pdf } from '../../services/api/pdf'
import { Assignees } from './'
import { calculateSalesOrderMetrics, getSalesOrderVolumeInCbm, getSalesOrderWeightInTonnes } from '../../utils/sales-orders'
import { DELIVERY_PLAN_ITEM_CHALLAN_LOADED } from '../challan/delivery-plan-item/[id]'
import AddRoutePointsModal from '../../components/add-route-points-modal'
import { deliveryPlanItemStatuses, orderStatuses, paths } from '../../utils/constants'
import AssignDriverModal from '../../components/assign-driver-modal'
import AssignHelperModal from '../../components/assign-helper-modal'
import UpdateVehicleModal from '../../components/update-vehicle-modal'
import AssignVehicleModal from '../../components/assign-vehicle-modal'
import { SALES_ORDER_STATUS_COLORS, SALES_ORDER_UNLOADED_SUB_STATUS } from '../sales-orders'
import StartLocationModal from '../../components/start-location-modal'
import { LocationTypes } from '../../components/location-modal'
import useSearchParams from '../../hooks/useSearchParams'
import HeaderTab from '../../components/header-tab'
import { DeliveryPlans } from '../../services/api/firebase'
import { usePrevious } from '../../hooks/usePrevious'
import { Routing } from '../../services/api/routing'
import { DELIVERY_PLAN_CANCELLED_STATUS, DELIVERY_PLAN_DELIVERED_STATUS, DELIVERY_PLAN_PENDING_STATUS, DELIVERY_PLAN_SCHEDULED_STATUS, DELIVERY_PLAN_STATUS_COLORS, getDeliveryPlanStatusLabel, getLoadedCapacityInPercentage, statusMenuItems } from '../../utils/delivery-plans'
import InternalNotesPopover from '../../components/internal-notes-popover'
import AssignTransportVendorModal from '../../components/assign-transport-vendor-modal'
import { usePage } from '../../hooks/usePage'
import { getVehicleTypeLabel } from '../../utils/vehicles'
import TextInput from '../../components/text-input'
import getFirebaseApp from '../../config/initFirebase'
import { getAuth } from 'firebase/auth'

const app = getFirebaseApp()
const auth = getAuth(app)

const { Content } = Layout

const getOrderStatus = (salesOrder) => {
	if (salesOrder.status.toLowerCase() === orderStatuses.SALES_ORDER_COMPLETED_STATUS) {
		return orderStatuses.SALES_ORDER_DELIVERED_STATUS
	} else if (salesOrder.subStatus) {
		return salesOrder.subStatus.toLowerCase() === SALES_ORDER_UNLOADED_SUB_STATUS ? 'Arrived' :
			salesOrder.subStatus.toLowerCase()
	} else {
		return salesOrder.status.toLowerCase()
	}
}

const tabs = [
	{
		title: 'Details',
		value: 'details'
	},
	{
		title: 'Logs',
		value: 'logs'
	}
]

const salesOrderColumns = [
	{
		title: 'Shipping Date',
		key: 'orderDate',
		render: salesOrder => {
			return moment(salesOrder.orderDate).format('MMM D, YYYY')
		}
	},
	{
		title: 'Order #',
		key: 'salesOrderID',
		render: salesOrder => {
			return (
				<Link href={`${paths.SALES_ORDERS}/${salesOrder.id}`}>
					<a className={styles.link}>{salesOrder.internalId}</a>
				</Link>
			)
		}
	},
	{
		title: 'Area Manager',
		key: 'areaManager',
		render: salesOrder => {
			if (salesOrder.distributor && salesOrder.distributor.accountManager) {
				return `${salesOrder.distributor.accountManager.name}`
			} else {
				return (
					<div className={styles.unassigned}>
						Unassigned
					</div>
				)
			}
		}
	},
	{
		title: 'Location',
		key: 'location',
		render: salesOrder => {
			const {
				distributor,
				location
			} = salesOrder
			return (
				!salesOrder.week &&
				<div className={styles.locations}>
					{
						distributor ?
							<div style={{ display: 'flex', flexDirection: 'column', marginBottom: 6 }}>
								<div className={styles.badge} style={{ color: '#278EA5', background: '#ecf9f9' }}>
									{convertEnumToString(distributor.type.toLowerCase())}
								</div>
								<Link href={`${paths.CUSTOMERS}/${distributor.id}`}>
									<a style={{ color: '#278EA5' }}>
										{`${distributor.name}${distributor.internalName ? ` (${distributor.internalName})` : ''}`}
									</a>
								</Link>
							</div> : null
					}
					{
						location &&
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							{
								!distributor &&
								<div className={styles.badge}>
									{convertEnumToString(location.type.toLowerCase())}
								</div>
							}
							{
								[LocationTypes.WAREHOUSE, LocationTypes.VIRTUAL_WAREHOUSE].includes(location.type) && !location.deletedAt ?
									<Link href={`${paths.WAREHOUSES}/${location.id}`}>
										<a>
											{location.label}
											<div className={styles.location}>
												{`${location.address}${location.internalId ? ` (${location.internalId})` : ''}`}
											</div>
										</a>
									</Link> :
									<div>
										{location.label}
										<div className={styles.location}>
											{`${location.address}${location.internalId ? ` (${location.internalId})` : ''}`}
										</div>
									</div>
							}
						</div>
					}
				</div>
			)
		}
	},
	{
		title: 'Weight',
		key: 'weight',
		render: salesOrder => {
			return `${getSalesOrderWeightInTonnes(salesOrder)} Tonnes`
		}
	},
	{
		title: 'Volume',
		key: 'volume',
		render: salesOrder => {
			return `${getSalesOrderVolumeInCbm(salesOrder)} CBM`
		}
	},
	{
		title: 'Sales Amount',
		key: 'salesAmount',
		render: salesOrder => {
			return `${getFormattedCurrency(salesOrder.totalAmount)}`
		}
	},
	{
		title: 'Advance Amount',
		key: 'advanceAmount',
		render: salesOrder => {
			return `${getFormattedCurrency(salesOrder.distributorAdvancePayment)}`
		}
	},
	{
		title: 'Payment Method',
		key: 'paymentMethod',
		render: salesOrder => {
			return `${convertEnumToString(salesOrder.paymentMethod)}`
		}
	},
	{
		title: 'Status',
		key: 'status',
		render: salesOrder => {
			return (
				<div style={{ display: 'flex' }}>
					<div className={styles.status} style={{
						background: salesOrder.subStatus ?
							ROUTE_STATUS_COLORS[salesOrder.subStatus.toLowerCase()] : SALES_ORDER_STATUS_COLORS[salesOrder.status.toLowerCase()]
					}}
					>
						{
							convertEnumToString(getOrderStatus(salesOrder))
						}
					</div>
				</div>
			)
		}
	}
]

export const ROUTE_STATUS_COLORS = {
	[deliveryPlanItemStatuses.ROUTE_GENERATED_STATUS]: '#1890ff',
	[deliveryPlanItemStatuses.ROUTE_SHIPPED_STATUS]: '#00C0C1',
	[deliveryPlanItemStatuses.ROUTE_IN_TRANSIT_STATUS]: '#2D9CDB',
	[deliveryPlanItemStatuses.ROUTE_UNLOADED_STATUS]: '#1E79AE',
	[deliveryPlanItemStatuses.ROUTE_COMPLETED_STATUS]: '#278EA5',
	[deliveryPlanItemStatuses.ROUTE_CANCELLED_STATUS]: '#6e7c91',
	[orderStatuses.SALES_ORDER_PAYMENT_COLLECTED_STATUS]: '#52c41a',
	[orderStatuses.SALES_ORDER_PAYMENT_DUE_STATUS]: '#e01e5a'
}

export const routeStatusMenuItems = [
	deliveryPlanItemStatuses.ROUTE_GENERATED_STATUS,
	deliveryPlanItemStatuses.ROUTE_SHIPPED_STATUS,
	deliveryPlanItemStatuses.ROUTE_IN_TRANSIT_STATUS,
	deliveryPlanItemStatuses.ROUTE_COMPLETED_STATUS,
	deliveryPlanItemStatuses.ROUTE_CANCELLED_STATUS
]

const WEIGHT_LIMIT = 13
const VOLUME_LIMIT = 32

const DeliveryPlanDetailsPage = () => {
	const router = useRouter()
	const dispatch = useDispatch()
	const { setPageChildTitle } = usePage()
	const { searchParams } = useSearchParams()
	const { companyDetails, permissions } = useSelector(state => state.authReducer)
	const { deliveryPlanDetails } = useSelector(state => state.deliveryPlansReducer)
	const { query, isReady } = router
	const { id } = query
	const [currentTab, setCurrentTab] = useState(tabs[0].value)
	const [isLoading, setIsLoading] = useState(false)
	const [totalAmount, setTotalAmount] = useState(0)
	const [totalWeightInTonnes, setTotalWeightInTonnes] = useState(0)
	const [totalVolumeInCbm, setTotalVolumeInCbm] = useState(0)
	const [distributorCount, setDistributorCount] = useState(0)
	const [isUpdatingStatus, setIsUpdatingStatus] = useState(false)
	const [isUpdatingRouteStatus, setIsUpdatingRouteStatus] = useState(false)
	const [isExportingPdf, setIsExportingPdf] = useState(false)
	const [isUpdating, setIsUpdating] = useState(false)
	const [deliveryPlanItemChallanUrl, setDeliveryPlanItemChallanUrl] = useState()
	const [isLoadingDeliveryPlanItem, setIsLoadingDeliveryPlanItem] = useState(false)
	const [editableDeliveryPlanDetail, setEditableDeliveryPlanDetail] = useState()
	const [loadingVehiclesDeliveryPlanId, setLoadingVehiclesDeliveryPlanId] = useState(null)
	const [selectedRowIds, setSelectedRowIds] = useState([])
	const [draggingRow, setDraggingRow] = useState(null)
	const [hoveredRouteId, setHoveredRouteId] = useState(null)
	const [selectedDeliveryPlanItem, setSelectedDeliveryPlanItem] = useState()
	const [selectedVehicleType, setSelectedVehicleType] = useState()
	const [cancellationReason, setCancellationReason] = useState('')
	const [isAddRoutePointsModalVisible, setIsAddRoutePointsModalVisible] = useState(false)
	const [isAssignDriverModalVisible, setIsAssignDriverModalVisible] = useState(false)
	const [isAssignHelperModalVisible, setIsAssignHelperModalVisible] = useState(false)
	const [isAssignVehicleModalVisible, setIsAssignVehicleModalVisible] = useState(false)
	const [isAssignTransportVendorModalVisible, setIsAssignTransportVendorModalVisible] = useState(false)
	const [isUpdateVehicleModalVisible, setIsUpdateVehicleModalVisible] = useState(false)
	const [addedSalesOrders, setAddedSalesOrders] = useState([])
	const [selectedSources, setSelectedSources] = useState({})
	const [isPickUpLocationModalVisible, setIsPickUpLocationModalVisible] = useState(false)
	const [isCompleteModalVisible, setIsCompleteModalVisible] = useState(false)
	const [isCancelModalVisible, setIsCancelModalVisible] = useState(false)
	const [deliveryPlanHistory, setDeliveryPlanHistory] = useState([])
	const [deliveryPlanHistoryMap, setDeliveryPlanHistoryMap] = useState({})
	const [nonGeneratedSalesOrders, setNonGeneratedSalesOrders] = useState([])
	const cancellationReasonInputRef = useRef('')
	const confirmModalRef = useRef('')
	const pageBottomRef = useRef()
	const deliveryPlanDetail = deliveryPlanDetails[id]

	useEffect(() => {
		editableDeliveryPlanDetail ?
			setPageChildTitle('Edit Delivery Plan') :
			setPageChildTitle('Delivery Plan Details')

		return () => {
			setPageChildTitle('')
		}
	}, [editableDeliveryPlanDetail])

	useEffect(() => {
		window.addEventListener('message', handleMessage)
		return () => {
			window.removeEventListener('message', handleMessage)
		}
	}, [])

	useEffect(() => {
		if (isReady) {
			if (!permissions.viewDeliveryPlans) {
				return
			}
			getDeliveryPlanDetails()
		}
	}, [isReady, searchParams])

	useEffect(() => {
		if (deliveryPlanDetail) {
			const metrics = calculateSalesOrderMetrics(deliveryPlanDetail.salesOrders)
			setTotalAmount(metrics.totalAmount)
			setTotalWeightInTonnes(metrics.totalWeightInTonnes)
			setTotalVolumeInCbm(metrics.totalVolumeInCbm)
			setDistributorCount(metrics.distributorCount)
		}
	}, [deliveryPlanDetail])

	useEffect(() => {
		const unsubscribeDeliveryPlanHistory = DeliveryPlans.listenToDeliveryPlanHistory(id, deliveryPlanHistoryItem => {
			if (!deliveryPlanHistoryMap[deliveryPlanHistoryItem.id]) {
				setDeliveryPlanHistory(oldDeliveryPlanHistory => [deliveryPlanHistoryItem, ...oldDeliveryPlanHistory])
				setDeliveryPlanHistoryMap(oldDeliveryPlanHistoryMap => ({ ...oldDeliveryPlanHistoryMap, [deliveryPlanHistoryItem.id]: true }))
			}
		})
		const unsubscribeDeliveryPlanItems = DeliveryPlans.listenToDeliveryPlanItems(id, null, () => {
			getDeliveryPlanDetails()
		})
		return () => {
			unsubscribeDeliveryPlanHistory()
			unsubscribeDeliveryPlanItems()
		}
	}, [isReady])

	const previousEditableDeliveryPlanDetail = usePrevious(editableDeliveryPlanDetail)
	useEffect(() => {
		if (previousEditableDeliveryPlanDetail && editableDeliveryPlanDetail) {
			const routes = {}
			const previousDeliveryPlanItems = previousEditableDeliveryPlanDetail.deliveryPlanItems || []
			const deliveryPlanItems = editableDeliveryPlanDetail.deliveryPlanItems || []
			if (JSON.stringify(previousDeliveryPlanItems) !== JSON.stringify(deliveryPlanItems)) {
				deliveryPlanItems.forEach(deliveryPlanItem => {
					const {
						id,
						route
					} = deliveryPlanItem
					const ids = route.slice(1).map(point => point.id)
					routes[id] = ids
				})
				getVehicleRecommendations(routes)
			}
		}
	}, [editableDeliveryPlanDetail])

	const getVehicleRecommendations = async (routes) => {
		try {
			if (editableDeliveryPlanDetail) {
				setLoadingVehiclesDeliveryPlanId(editableDeliveryPlanDetail.id)
				const response = await Routing.fetchVehicleRecommendation(routes)
				const { routeVehicleTypes } = response.data
				const newEditableDeliveryPlan = { ...editableDeliveryPlanDetail }
				const deliveryPlanItems = newEditableDeliveryPlan.deliveryPlanItems || []
				deliveryPlanItems.forEach(deliveryPlanItem => {
					const vehicles = routeVehicleTypes[deliveryPlanItem.id]
					if (vehicles) {
						if (vehicles.length > 1) {
							deliveryPlanItem.recommendedVehicle = null
							deliveryPlanItem.error = 'Too many orders in this route.'
						} else if (vehicles.length === 1) {
							deliveryPlanItem.recommendedVehicle = vehicles[0]
						} else {
							deliveryPlanItem.recommendedVehicle = null
							deliveryPlanItem.error = 'Could not find a vehicle that can fit this route.'
						}
					}
				})
				setEditableDeliveryPlanDetail(newEditableDeliveryPlan)
			}
		} catch (e) {
			notification.error({
				message: 'Unable to get vehicle recommendations.',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setLoadingVehiclesDeliveryPlanId(null)
		}
	}


	const handleMessage = (event) => {
		if (event.data.action === DELIVERY_PLAN_ITEM_CHALLAN_LOADED) {
			const iframe = document.frames ? document.frames['delivery-plan-item'] : document.getElementById('delivery-plan-item')
			const iframeWindow = iframe.contentWindow || iframe
			iframe.focus()
			iframeWindow.print()
			setDeliveryPlanItemChallanUrl(null)
			setIsLoadingDeliveryPlanItem(false)
		}
	}

	const setNonGeneratedOrders = (deliveryPlan) => {
		const deliveryPlanItemOrderIds = []
		for (const item of deliveryPlan.deliveryPlanItems) {
			for (const order of item.salesOrders) {
				deliveryPlanItemOrderIds.push(order.id)
			}
		}
		setNonGeneratedSalesOrders(deliveryPlan.salesOrders.filter(o => !deliveryPlanItemOrderIds.includes(o.id)))
	}

	const getDeliveryPlanDetails = async () => {
		try {
			setIsLoading(true)
			const deliveryPlan = await dispatch(fetchDeliveryPlanDetails(id, searchParams.startLocationId))
			setNonGeneratedOrders(deliveryPlan)
		} catch (e) {
			notification.error({
				message: 'Unable to Fetch Delivery Plan Details.',
				description: `${e.message}`,
				placement: 'bottomLeft'
			})
		} finally {
			setIsLoading(false)

		}
	}

	const onUpdateStatus = (status) => {
		if (status === DELIVERY_PLAN_DELIVERED_STATUS) {
			setIsCompleteModalVisible(true)
		} else if (status === DELIVERY_PLAN_CANCELLED_STATUS) {
			setIsCancelModalVisible(true)
		} else {
			updateDeliveryPlanStatus(status)
		}
	}

	const updateDeliveryPlanStatus = async (status, cancellationReason = '') => {
		try {
			setIsUpdatingStatus(true)
			await dispatch(updateDeliveryPlan(id, {
				status,
				cancellationReason
			}))
			await getDeliveryPlanDetails()
		} catch (e) {
			notification.error({
				message: 'Unable to Update Delivery Plan\'s Status.',
				description: `${e.message}`,
				placement: 'bottomLeft'
			})
		} finally {
			setIsUpdatingStatus(false)
		}
	}

	const renderRouteCancellationConfirmContent = (status, deliveryPlanItem) => {
		return (
			<div>
				<div style={{ marginBottom: 8 }}>{`Are you sure you want to override the status of the Delivery Plan Route ${deliveryPlanItem.internalId} to ${convertEnumToString(status)}?`}</div>
				<TextInput
					title='Cancelled Reason'
					onChange={(e) => {
						const value = e.target.value
						cancellationReasonInputRef.current = value
						confirmModalRef.current?.update({ okButtonProps: { disabled: !value?.trim() } })
					}}
				/>
			</div>
		)
	}

	const onUpdateRouteStatus = async (id, status, deliveryPlanItem) => {
		const statusesToConfirm = [
			deliveryPlanItemStatuses.ROUTE_GENERATED_STATUS,
			deliveryPlanItemStatuses.ROUTE_SHIPPED_STATUS,
			deliveryPlanItemStatuses.ROUTE_IN_TRANSIT_STATUS,
			deliveryPlanItemStatuses.ROUTE_COMPLETED_STATUS
		]
		if (statusesToConfirm.includes(status)) {
			AntModal.confirm({
				title: 'Update Delivery Plan Route\'s Status',
				content: `Are you sure you want to override the status of the Delivery Plan Route ${deliveryPlanItem.internalId} to ${convertEnumToString(status)}?`,
				okText: 'Yes',
				cancelText: 'No',
				closable: true,
				onOk: () => updateRouteStatus(id, status)
			})
		} else if (status === deliveryPlanItemStatuses.ROUTE_CANCELLED_STATUS) {
			confirmModalRef.current = Modal.confirm({
				title: 'Update Delivery Plan Route\'s Status',
				content: renderRouteCancellationConfirmContent(status, deliveryPlanItem),
				okButtonProps: { disabled: true },
				okText: 'Yes',
				cancelText: 'No',
				closable: true,
				onOk: async () => {
					await updateRouteStatus(id, status, cancellationReasonInputRef.current)
					cancellationReasonInputRef.current = ''
				}
			})
		} else {
			updateRouteStatus(id, status)
		}
	}

	const updateRouteStatus = async (id, status, cancellationReason = '') => {
		try {
			setIsUpdatingRouteStatus(true)
			await dispatch(updateDeliveryPlanItemStatus(id, status, cancellationReason))
			await getDeliveryPlanDetails()
		} catch (e) {
			notification.error({
				message: 'Unable to Update Route\'s Status.',
				description: `${e.message}`,
				placement: 'bottomLeft'
			})
		} finally {
			setIsUpdatingRouteStatus(false)
		}
	}

	const isRouteStatusDisabled = () => {
		const deliveryPlanStatus = deliveryPlanDetail.status
		switch (deliveryPlanStatus) {
			case DELIVERY_PLAN_PENDING_STATUS:
				return true
			default:
				return false
		}
	}

	const onExportPdf = async () => {
		setIsExportingPdf(true)
		await Pdf.downloadDeliveryPlan(id)
		setIsExportingPdf(false)
	}

	const getFormattedDeliveryPlanDetail = (deliveryPlanDetail) => {
		const deliveryPlan = editableDeliveryPlanDetail ? editableDeliveryPlanDetail : deliveryPlanDetail
		if (deliveryPlan) {
			const salesOrders = deliveryPlan.salesOrders || []
			deliveryPlan.deliveryPlanItems?.forEach(deliveryPlanItem => {
				// Re-order sales orders based on the route.
				const deliveryPlanItemSalesOrders = []
				const routeOrders = deliveryPlanItem.salesOrders || []
				for (const point of deliveryPlanItem.route) {
					const salesOrder = [...salesOrders, ...routeOrders].find(salesOrder => point.id === salesOrder.id)
					if (salesOrder) {
						deliveryPlanItemSalesOrders.push(salesOrder)
					}
				}
				deliveryPlanItem.salesOrders = deliveryPlanItemSalesOrders
			})
		}
		return deliveryPlan
	}

	const onPrintDeliveryPlanItem = async (deliveryPlanItem) => {
		setIsLoadingDeliveryPlanItem(true)
		const token = await auth.currentUser.getIdToken()
		setDeliveryPlanItemChallanUrl(`/challan/delivery-plan-item/${deliveryPlanItem.id}?accessToken=${token}`)
	}

	const scrollToBottom = () => {
		if (pageBottomRef.current) {
			pageBottomRef.current.scrollIntoView(
				{
					behavior: 'smooth',
					block: 'end',
					inline: 'nearest'
				}
			)
		}
	}

	const onEdit = () => {
		const editableDeliveryPlan = JSON.parse(JSON.stringify(deliveryPlanDetail))
		setEditableDeliveryPlanDetail(editableDeliveryPlan)
	}

	const onAddNewRoute = async (location) => {
		const newDeliveryPlanItem = {
			id: `new_${editableDeliveryPlanDetail.deliveryPlanItems.length}_${location.id}`,
			route: [
				{
					id: `vehicle_${editableDeliveryPlanDetail.deliveryPlanItems.length}_0-start`,
					address: location.address,
					locationId: location.id,
					lat: +location.latitude,
					lon: +location.longitude,
					distance: 0
				}
			],
			startLocation: location
		}
		const newEditableDeliveryPlan = { ...editableDeliveryPlanDetail }
		newEditableDeliveryPlan.deliveryPlanItems.push(newDeliveryPlanItem)
		setEditableDeliveryPlanDetail(newEditableDeliveryPlan)
		scrollToBottom()
	}

	const isSelectedRow = (rowId) => selectedRowIds.includes(rowId)

	const isGhosting = rowId => isSelectedRow(rowId) && draggingRow

	const onBeforeCapture = (start) => {
		const draggableId = start.draggableId
		const selected = selectedRowIds.find((id) => id === draggableId)
		if (!selected) {
			setSelectedRowIds([])
			setSelectedSources({})
		}
		setDraggingRow(draggableId)
	}

	const onRowSelectChange = (salesOrderIds, deliveryPlanItem) => {
		setSelectedRowIds(salesOrderIds)
		const newSelectedSources = { ...selectedSources }
		if (deliveryPlanItem.salesOrders.some(so => salesOrderIds.includes(so.id))) {
			newSelectedSources[deliveryPlanItem.id] = true
		} else {
			delete newSelectedSources[deliveryPlanItem.id]
		}
		setSelectedSources(newSelectedSources)
	}

	const handleMultiDrag = (destination) => {
		const newEditableDeliveryPlan = JSON.parse(JSON.stringify(editableDeliveryPlanDetail))
		const points = []
		const sourceItems = []
		newEditableDeliveryPlan.deliveryPlanItems.forEach(item => {
			if (selectedSources[item.id]) {
				sourceItems.push(item)
				item.route = item.route.filter(point => {
					if (selectedRowIds.includes(point.id)) {
						points.push(point)
						return false
					}
					return true
				})
			}
		})

		const destinationItemIndex = editableDeliveryPlanDetail.deliveryPlanItems.findIndex(item => item.id === destination.droppableId)
		const destinationItem = editableDeliveryPlanDetail.deliveryPlanItems[destinationItemIndex]
		if (destinationItem && sourceItems.some(item => item.startlocationId !== destinationItem.startLocationId)) {
			AntModal.confirm({
				title: 'Update Pick Up Location',
				content: 'Pick up locations will be updated for selected orders, are you sure you want to continue?',
				okText: 'Yes',
				cancelText: 'No',
				closable: true,
				onOk: () => {
					destinationItem.route = [...destinationItem.route, ...points]
					newEditableDeliveryPlan.deliveryPlanItems[destinationItemIndex] = destinationItem
					setEditableDeliveryPlanDetail(newEditableDeliveryPlan)
				}
			})
		} else {
			if (destinationItem) {
				destinationItem.route = [...destinationItem.route, ...points]
				newEditableDeliveryPlan.deliveryPlanItems[destinationItemIndex] = destinationItem
				setEditableDeliveryPlanDetail(newEditableDeliveryPlan)
			}
		}
	}

	const handleSingleDrag = ({ source, destination, draggableId }) => {
		const sourceItemIndex = editableDeliveryPlanDetail.deliveryPlanItems.findIndex(item => item.id === source.droppableId)
		const destinationItemIndex = editableDeliveryPlanDetail.deliveryPlanItems.findIndex(item => item.id === destination.droppableId)
		const sourceItem = editableDeliveryPlanDetail.deliveryPlanItems[sourceItemIndex]
		const destinationItem = editableDeliveryPlanDetail.deliveryPlanItems[destinationItemIndex]
		if (sourceItem && destinationItem) {
			if (sourceItem.startLocationId !== destinationItem.startLocationId) {
				AntModal.confirm({
					title: 'Update Pick Up Location',
					content: 'Pick up locations will be updated for selected orders, are you sure you want to continue?',
					okText: 'Yes',
					cancelText: 'No',
					closable: true,
					onOk: () => handleRouteUpdate({
						sourceItemIndex,
						destinationItemIndex,
						draggableId
					})
				})
			} else {
				handleRouteUpdate({
					sourceItemIndex,
					destinationItemIndex,
					draggableId
				})
			}
		}
	}

	const handleRouteUpdate = ({ sourceItemIndex, destinationItemIndex, draggableId }) => {
		const newEditableDeliveryPlan = { ...editableDeliveryPlanDetail }
		const sourceItem = editableDeliveryPlanDetail.deliveryPlanItems[sourceItemIndex]
		const destinationItem = editableDeliveryPlanDetail.deliveryPlanItems[destinationItemIndex]
		if (sourceItem && destinationItem) {
			const point = sourceItem.route.find(point => point.id === draggableId)
			sourceItem.route = sourceItem.route.filter(point => point.id !== draggableId)
			destinationItem.route.push(point)
			newEditableDeliveryPlan.deliveryPlanItems[sourceItemIndex] = sourceItem
			newEditableDeliveryPlan.deliveryPlanItems[destinationItemIndex] = destinationItem
		}
		setEditableDeliveryPlanDetail(newEditableDeliveryPlan)
	}

	const updateRouteFromNonGeneratedOrders = (orderIds, destinationDeliveryPlanItemId) => {
		const newEditableDeliveryPlan = { ...editableDeliveryPlanDetail }
		const deliveryPlanItemIndex = newEditableDeliveryPlan.deliveryPlanItems.findIndex(item => item.id === destinationDeliveryPlanItemId)
		const newDeliveryPlanItem = { ...newEditableDeliveryPlan.deliveryPlanItems[deliveryPlanItemIndex] }
		const route = newDeliveryPlanItem.route
		const firstPoint = route[0]
		const startLat = +firstPoint.lat
		const startLon = +firstPoint.lon
		const newRoute = []
		const newSalesOrders = []
		const salesOrders = nonGeneratedSalesOrders.filter(item => orderIds.includes(item.id))
		salesOrders.forEach(salesOrder => {
			const lat = +salesOrder.location.latitude
			const lon = +salesOrder.location.longitude
			const point = {
				distance: getDistanceFromLatLonInMeters(startLat, startLon, lat, lon),
				id: salesOrder.id,
				lat,
				lon
			}
			newRoute.push(point)
			const newSalesOrder = { ...salesOrder }
			newSalesOrder.pickUpLocationId = firstPoint.locationId
			newSalesOrder.pickUpLocation = {
				id: firstPoint.locationId,
				address: firstPoint.address,
				latitude: +firstPoint.lat,
				longitude: +firstPoint.lon
			}
			newSalesOrders.push(newSalesOrder)
		})
		newDeliveryPlanItem.route = [...route, ...newRoute]
		newEditableDeliveryPlan.salesOrders = [...newEditableDeliveryPlan.salesOrders, ...newSalesOrders]
		newEditableDeliveryPlan.deliveryPlanItems[deliveryPlanItemIndex] = newDeliveryPlanItem
		setEditableDeliveryPlanDetail(newEditableDeliveryPlan)
		setNonGeneratedSalesOrders(nonGeneratedSalesOrders.filter(item => !orderIds.includes(item.id)))
	}

	const onDragEnd = ({ source, destination, draggableId }) => {
		setDraggingRow(null)
		if (
			!destination ||
			source.droppableId === destination.droppableId ||
			destination.droppableId === 'non_generated' ||
			selectedSources[destination.droppableId]
		) {
			return
		}
		if (source.droppableId === 'non_generated') {
			updateRouteFromNonGeneratedOrders([draggableId], destination.droppableId)
		} else if (selectedRowIds.length > 0) {
			handleMultiDrag(destination)
		} else {
			handleSingleDrag({ source, destination, draggableId })
		}
		setSelectedRowIds([])
		setSelectedSources({})
	}

	const onRemoveRouteItem = (deliveryPlanItem, point) => {
		const newEditableDeliveryPlan = { ...editableDeliveryPlanDetail }
		const deliveryPlanItemIndex = newEditableDeliveryPlan.deliveryPlanItems.findIndex(item => item.id === deliveryPlanItem.id)
		const newDeliveryPlanItem = { ...deliveryPlanItem }
		newDeliveryPlanItem.route = newDeliveryPlanItem.route.filter(p => p.id !== point.id)
		newEditableDeliveryPlan.deliveryPlanItems[deliveryPlanItemIndex] = newDeliveryPlanItem
		setEditableDeliveryPlanDetail(newEditableDeliveryPlan)
	}

	const getDroppableStyle = (isDraggingOver) => {
		return isDraggingOver ?
			{
				border: '1px solid #278EA5'
			} : {}
	}

	const onEditCancel = () => {
		setEditableDeliveryPlanDetail(null)
		setSelectedSources({})
		setSelectedRowIds([])
		setAddedSalesOrders([])
	}

	const onSave = async () => {
		try {
			if (editableDeliveryPlanDetail) {
				const deliveryPlanItems = editableDeliveryPlanDetail.deliveryPlanItems
				const routes = {}
				const vehicles = {}
				deliveryPlanItems.forEach(deliveryPlanItem => {
					routes[deliveryPlanItem.id] = deliveryPlanItem.route
					vehicles[deliveryPlanItem.id] = deliveryPlanItem.recommendedVehicle?.value
				})
				setIsUpdating(true)
				const payload = {
					routes,
					vehicles,
					salesOrderIds: addedSalesOrders.map(so => so.id)
				}
				await dispatch(updateDeliveryPlanRoutes(deliveryPlanDetail.id, payload))
				notification.success({
					message: 'Delivery plan updated',
					description: 'Delivery plan successfully updated!',
					placement: 'bottomLeft'
				})
				setIsUpdating(false)
				setEditableDeliveryPlanDetail(null)
				setAddedSalesOrders([])
			}
		} catch (err) {
			notification.error({
				message: 'Unable to update.',
				description: err.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsUpdating(false)
		}
	}

	const onUnassignVehicle = deliveryPlanItem => {
		AntModal.confirm({
			title: 'Unassign Vehicle',
			content: 'Are you sure you want to unassign the vehicle from this route?',
			onOk: async () => {
				await dispatch(assignVehicleToDeliveryPlanItem(deliveryPlanItem.id, {
					vehicleId: deliveryPlanItem.vehicleId,
					unassign: 'true'
				}))
			}
		})
	}

	const onUnassignDriver = deliveryPlanItem => {
		AntModal.confirm({
			title: 'Unassign Driver',
			content: 'Are you sure you want to unassign the driver from this route?',
			onOk: async () => {
				await dispatch(assignVehicleToDeliveryPlanItem(deliveryPlanItem.id, {
					driverId: deliveryPlanItem.driverId,
					unassign: 'true'
				}))
			}
		})
	}

	const onUnassignHelper = deliveryPlanItem => {
		AntModal.confirm({
			title: 'Unassign Helper',
			content: 'Are you sure you want to unassign the helper from this route?',
			onOk: async () => {
				await dispatch(assignVehicleToDeliveryPlanItem(deliveryPlanItem.id, {
					helperId: deliveryPlanItem.helperId,
					unassign: 'true'
				}))
			}
		})
	}

	const addRoutePoints = (salesOrders) => {
		const newEditableDeliveryPlan = { ...editableDeliveryPlanDetail }
		const deliveryPlanItemIndex = newEditableDeliveryPlan.deliveryPlanItems.findIndex(item => item.id === selectedDeliveryPlanItem.id)
		const newDeliveryPlanItem = { ...newEditableDeliveryPlan.deliveryPlanItems[deliveryPlanItemIndex] }
		const route = newDeliveryPlanItem.route
		const firstPoint = route[0]
		const startLat = +firstPoint.lat
		const startLon = +firstPoint.lon
		const newRoute = []
		const newSalesOrders = []
		salesOrders.forEach(salesOrder => {
			const lat = +salesOrder.location.latitude
			const lon = +salesOrder.location.longitude
			const point = {
				distance: getDistanceFromLatLonInMeters(startLat, startLon, lat, lon),
				id: salesOrder.id,
				lat,
				lon
			}
			newRoute.push(point)
			const newSalesOrder = { ...salesOrder }
			newSalesOrder.pickUpLocationId = firstPoint.locationId
			newSalesOrder.pickUpLocation = {
				id: firstPoint.locationId,
				address: firstPoint.address,
				latitude: +firstPoint.lat,
				longitude: +firstPoint.lon
			}
			newSalesOrders.push(newSalesOrder)
		})
		newDeliveryPlanItem.route = [...route, ...newRoute]
		newEditableDeliveryPlan.salesOrders = [...newEditableDeliveryPlan.salesOrders, ...newSalesOrders]
		newEditableDeliveryPlan.deliveryPlanItems[deliveryPlanItemIndex] = newDeliveryPlanItem
		setEditableDeliveryPlanDetail(newEditableDeliveryPlan)
		setAddedSalesOrders(newSalesOrders)
	}

	const getNonOverWeightedRows = (totalWeight, maxWeightLimit = WEIGHT_LIMIT, salesOrders) => {
		const results = []
		if (totalWeight <= maxWeightLimit) {
			return salesOrders.map(so => so.id)
		}
		let weightLimit = maxWeightLimit
		const weights = salesOrders.map(so => {
			return {
				id: so.id,
				weight: +getSalesOrderWeightInTonnes(so)
			}
		}).sort((w1, w2) => w2.weight - w1.weight)

		while (weightLimit > 0) {
			let current = weights[0]
			let difference = Number.POSITIVE_INFINITY

			for (let i = 0; i < weights.length; i++) {
				const newDifference = weightLimit - weights[i].weight
				if (!results.includes(weights[i].id) && newDifference >= 0) {
					if (difference > newDifference) {
						difference = newDifference
						current = weights[i]
					}
				}
			}

			results.push(current.id)
			weightLimit -= current.weight
		}
		return results
	}

	const getNonOverVolumedRows = (totalVolume, maxVolumeLimit = VOLUME_LIMIT, salesOrders) => {
		const results = []
		if (totalVolume <= maxVolumeLimit) {
			return salesOrders.map(so => so.id)
		}
		let volumeLimit = maxVolumeLimit
		const volumes = salesOrders.map(so => {
			return {
				id: so.id,
				volume: +getSalesOrderVolumeInCbm(so)
			}
		}).sort((w1, w2) => w2.volume - w1.volume)

		while (volumeLimit > 0) {
			let current = volumes[0]
			let difference = Number.POSITIVE_INFINITY

			for (let i = 0; i < volumes.length; i++) {
				const newDifference = volumeLimit - volumes[i].volume
				if (!results.includes(volumes[i].id) && newDifference >= 0) {
					if (difference > newDifference) {
						difference = newDifference
						current = volumes[i]
					}
				}
			}

			results.push(current.id)
			volumeLimit -= current.volume
		}
		return results
	}

	const updateInternalNotes = async (id, notes) => {
		await dispatch(updateDeliveryPlanItem(id, {
			internalNotes: notes
		}))
	}

	const isMenuItemDisabled = (deliveryPlanItem) => {
		return deliveryPlanDetail?.status === DELIVERY_PLAN_PENDING_STATUS ||
		deliveryPlanItem.status.toLowerCase() === deliveryPlanItemStatuses.ROUTE_CANCELLED_STATUS
	}

	const renderRouteMenu = (deliveryPlanItem) => {
		return (
			<Dropdown
				overlay={(
					<Menu>
						{
							deliveryPlanItem.assignedDriverId ?
								<Menu.Item
									onClick={() => onUnassignDriver(deliveryPlanItem)}
									key='1'
									disabled={isMenuItemDisabled(deliveryPlanItem)}
								>
									Unassign Driver
								</Menu.Item>
								:
								<Menu.Item
									onClick={() => {
										setIsAssignDriverModalVisible(true)
										setSelectedDeliveryPlanItem(deliveryPlanItem)
									}}
									key='2'
									disabled={isMenuItemDisabled(deliveryPlanItem)}
								>
									Assign Driver
								</Menu.Item>
						}
						{
							deliveryPlanItem.assignedHelperId ?
								<Menu.Item
									onClick={() => onUnassignHelper(deliveryPlanItem)}
									key='3'
									disabled={isMenuItemDisabled(deliveryPlanItem)}
								>
									Unassign Helper
								</Menu.Item>
								:
								<Menu.Item
									onClick={() => {
										setIsAssignHelperModalVisible(true)
										setSelectedDeliveryPlanItem(deliveryPlanItem)
									}}
									key='4'
									disabled={isMenuItemDisabled(deliveryPlanItem)}
								>
									Assign Helper
								</Menu.Item>
						}
						{
							deliveryPlanItem.currentVehicle ?
								<Menu.Item
									onClick={() => onUnassignVehicle(deliveryPlanItem)}
									key='5'
								>
									Unassign Vehicle
								</Menu.Item> :
								<Menu.Item
									onClick={() => {
										setIsAssignVehicleModalVisible(true)
										setSelectedDeliveryPlanItem(deliveryPlanItem)
										setSelectedVehicleType(deliveryPlanItem.vehicleType)
									}}
									key='6'
									disabled={isMenuItemDisabled(deliveryPlanItem)}
								>
									Assign Vehicle
								</Menu.Item>
						}
						<Menu.Item
							onClick={() => {
								setIsUpdateVehicleModalVisible(true)
								setSelectedDeliveryPlanItem(deliveryPlanItem)
								if (deliveryPlanItem.vehicleType) {
									setSelectedVehicleType(deliveryPlanItem.vehicleType)
								} else if (deliveryPlanItem.recommendedVehicle) {
									setSelectedVehicleType(deliveryPlanItem.recommendedVehicle.value)
								}
							}}
							key='7'
							disabled={isMenuItemDisabled(deliveryPlanItem)}
						>
							Update Vehicle Type
						</Menu.Item>
						{
							companyDetails?.is3plIntegrationsEnabled &&
							<Menu.Item
								onClick={() => {
									setIsAssignTransportVendorModalVisible(true)
									setSelectedDeliveryPlanItem(deliveryPlanItem)
								}}
								key='8'
								disabled={isMenuItemDisabled(deliveryPlanItem)}
							>
								Assign Transport Vendors
							</Menu.Item>
						}
					</Menu>
				)}
				trigger={['click']}
			>
				<MoreOutlined
					style={{
						marginLeft: 12,
						alignItems: 'center',
						display: 'flex'
					}}
				/>
			</Dropdown>
		)
	}

	const renderDraggableBodyRow = (props, rows, nonOverWeightedRows = []) => {
		if (!props['data-row-key']) {
			return <tr {...props} />
		}
		const index = rows.findIndex(row => row.id === props['data-row-key'])
		return (
			<Draggable
				isDragDisabled={!editableDeliveryPlanDetail}
				draggableId={props['data-row-key']}
				key={props['data-row-key']}
				index={index}
			>
				{
					(provided, snapshot) => {
						return (
							<tr
								{...props}
								ref={provided.innerRef}
								className={`
									${snapshot.isDragging ? styles.draggableRow : ''} 
									${editableDeliveryPlanDetail ? styles.dragHand : ''}
									${isGhosting(props['data-row-key']) ? styles.rowGhosting : ''}
									${!nonOverWeightedRows.includes(props['data-row-key']) ? styles.overWeight : ''}
								`}
								{...provided.draggableProps}
								{...provided.dragHandleProps}
							/>
						)
					}
				}
			</Draggable>
		)
	}

	const formattedDeliveryPlanDetail = useMemo(() => {
		const deliveryPlan = getFormattedDeliveryPlanDetail(deliveryPlanDetail)
		if (!deliveryPlan) {
			return deliveryPlanDetail
		}
		deliveryPlan.deliveryPlanItems.forEach(deliveryPlanItem => {
			const {
				salesOrders,
				recommendedVehicle,
				vehicle
			} = deliveryPlanItem
			let truckWeightCapacity = WEIGHT_LIMIT
			let truckVolumeCapacity = VOLUME_LIMIT
			if (recommendedVehicle) {
				truckWeightCapacity = recommendedVehicle.maxWeight
				truckVolumeCapacity = recommendedVehicle.cbm
			}
			let truckMaxWeightCapacity = 0
			let truckMaxVolumeCapacity = 0
			if (vehicle) {
				truckMaxWeightCapacity = vehicle.maxWeightCapacity || 0
				truckMaxVolumeCapacity = vehicle.maxVolumeCapacity || 0
			}
			const { totalAmount, totalWeightInTonnes, totalVolumeInCbm } = calculateSalesOrderMetrics(salesOrders)
			const loadedWeight = getLoadedCapacityInPercentage(totalWeightInTonnes, truckWeightCapacity)
			const nonOverWeightedRows = getNonOverWeightedRows(totalWeightInTonnes, truckWeightCapacity, salesOrders)
			const loadedVolume = getLoadedCapacityInPercentage(totalVolumeInCbm, truckVolumeCapacity)
			const nonOverVolumedRows = getNonOverVolumedRows(totalVolumeInCbm, truckVolumeCapacity, salesOrders)
			const actualLoadedWeight = getLoadedCapacityInPercentage(totalWeightInTonnes, truckMaxWeightCapacity)
			const actualLoadedVolume = getLoadedCapacityInPercentage(totalVolumeInCbm, truckMaxVolumeCapacity)
			deliveryPlanItem.metadata = {
				totalAmount,
				totalWeightInTonnes,
				totalVolumeInCbm,
				loadedWeight: isNaN(loadedWeight) ? 0 : loadedWeight,
				nonOverWeightedRows,
				loadedVolume: isNaN(loadedVolume) ? 0 : loadedVolume,
				nonOverVolumedRows,
				actualLoadedVolume,
				actualLoadedWeight
			}
		})
		return deliveryPlan
	}, [deliveryPlanDetail, editableDeliveryPlanDetail])

	const modifiedStatusMenuItems = useMemo(() => {
		if (deliveryPlanDetail) {
			switch (deliveryPlanDetail.status) {
				case DELIVERY_PLAN_PENDING_STATUS:
					return [
						DELIVERY_PLAN_PENDING_STATUS,
						DELIVERY_PLAN_SCHEDULED_STATUS,
						DELIVERY_PLAN_CANCELLED_STATUS
					]
				case DELIVERY_PLAN_SCHEDULED_STATUS: {
					const showAllRoutes = deliveryPlanDetail.deliveryPlanItems.every(item => {
						return [deliveryPlanItemStatuses.ROUTE_GENERATED_STATUS, deliveryPlanItemStatuses.ROUTE_SHIPPED_STATUS, deliveryPlanItemStatuses.ROUTE_CANCELLED_STATUS].includes(item.status.toLowerCase())
					})
					return showAllRoutes ? statusMenuItems : [DELIVERY_PLAN_SCHEDULED_STATUS, DELIVERY_PLAN_DELIVERED_STATUS]
				}
				case DELIVERY_PLAN_DELIVERED_STATUS:
					return [DELIVERY_PLAN_DELIVERED_STATUS]
				case DELIVERY_PLAN_CANCELLED_STATUS:
					return [DELIVERY_PLAN_CANCELLED_STATUS]
				default:
					return []
			}
		}
		return statusMenuItems
	}, [deliveryPlanDetail])

	const getRouteStatusMenuItems = (status) => {
		switch (status) {
			case deliveryPlanItemStatuses.ROUTE_GENERATED_STATUS:
				return [
					deliveryPlanItemStatuses.ROUTE_GENERATED_STATUS,
					deliveryPlanItemStatuses.ROUTE_SHIPPED_STATUS,
					deliveryPlanItemStatuses.ROUTE_CANCELLED_STATUS
				]
			case deliveryPlanItemStatuses.ROUTE_SHIPPED_STATUS:
				return [
					deliveryPlanItemStatuses.ROUTE_GENERATED_STATUS,
					deliveryPlanItemStatuses.ROUTE_SHIPPED_STATUS,
					deliveryPlanItemStatuses.ROUTE_IN_TRANSIT_STATUS,
					deliveryPlanItemStatuses.ROUTE_CANCELLED_STATUS
				]
			case deliveryPlanItemStatuses.ROUTE_IN_TRANSIT_STATUS:
				return [
					deliveryPlanItemStatuses.ROUTE_IN_TRANSIT_STATUS,
					deliveryPlanItemStatuses.ROUTE_COMPLETED_STATUS
				]
			case deliveryPlanItemStatuses.ROUTE_COMPLETED_STATUS:
				return [
					deliveryPlanItemStatuses.ROUTE_COMPLETED_STATUS
				]
			case deliveryPlanItemStatuses.ROUTE_CANCELLED_STATUS:
				return [
					deliveryPlanItemStatuses.ROUTE_CANCELLED_STATUS
				]
			default:
				return []
		}
	}

	const renderTravelInfo = (deliveryPlanItem) => {
		return (
			<div className={styles.travelInfo}>
				<div className={styles.travelRouteInfo}>
					<div className={styles.travelInfoTitle}>Delivery Route:</div>
					<div className={styles.travelInfoValue}>{` ${((+deliveryPlanItem.travelDistance || 0) / 1000).toFixed(2)} km; ${moment.duration((+deliveryPlanItem.travelTime || 0) * 1000).humanize()}`}</div>
				</div>
				<div className={styles.travelRouteInfo}>
					<div className={styles.travelInfoTitle}>Return Route:</div>
					<div className={styles.travelInfoValue}>{` ${((+deliveryPlanItem.returnTravelDistance || 0) / 1000).toFixed(2)} km; ${moment.duration((+deliveryPlanItem.returnTravelTime || 0) * 1000).humanize()}`}</div>
				</div>
			</div>
		)
	}

	const renderRouteHeaderInfo = (deliveryPlanItem, index) => {
		const {
			metadata,
			vehicle,
			currentVehicle
		} = deliveryPlanItem
		const {
			totalAmount,
			totalWeightInTonnes,
			totalVolumeInCbm,
			loadedWeight,
			loadedVolume,
			actualLoadedVolume,
			actualLoadedWeight
		} = metadata ? metadata : {}

		return (
			<div className={styles.routeInfo}>
				<div className={styles.idStatusMessageContainer}>
					<div className={styles.idStatusContainer}>
						<div className={styles.idContainer}>
							<h3 style={{ fontSize: '14px' }}>
								{deliveryPlanItem.internalId ? deliveryPlanItem.internalId : index + 1}
							</h3>
							{
								deliveryPlanDetail.status === DELIVERY_PLAN_SCHEDULED_STATUS &&
								<Button
									loading={isLoadingDeliveryPlanItem}
									onClick={() => onPrintDeliveryPlanItem(deliveryPlanItem)}
									style={{ marginLeft: 20 }} icon={<PrinterOutlined />}
								/>
							}
						</div>
						{
							deliveryPlanItem.status &&
							<Tooltip title='Update route status'>
								<Dropdown
									trigger={['click']}
									disabled={isRouteStatusDisabled()}
									overlay={() => {
										return (
											<Menu>
												{
													getRouteStatusMenuItems(deliveryPlanItem.status.toLowerCase()).map(status => {
														return (
															<Menu.Item
																key={status}
																onClick={() => onUpdateRouteStatus(deliveryPlanItem.id, status, deliveryPlanItem)}
																disabled={status === deliveryPlanItem.status.toLowerCase()}
															>
																<Badge
																	text={convertEnumToString(status)}
																	color={ROUTE_STATUS_COLORS[status]}
																/>
															</Menu.Item>
														)
													})
												}
											</Menu>
										)
									}}
								>
									<Button
										loading={isUpdatingRouteStatus}
										type='primary'
										icon={<DownOutlined />}
										style={{
											marginLeft: 16,
											background: ROUTE_STATUS_COLORS[deliveryPlanItem.status.toLowerCase()],
											borderColor: ROUTE_STATUS_COLORS[deliveryPlanItem.status.toLowerCase()],
											color: isRouteStatusDisabled() ? '#d9d9d9' : 'white'
										}}
									>
										{convertEnumToString(deliveryPlanItem.status.toLowerCase())}
									</Button>
								</Dropdown>
							</Tooltip>
						}
					</div>
					{
						deliveryPlanItem?.shipmentId ?
							<div className={styles.routeItemMessage}>
								<strong style={{ marginRight: '4px' }}>Shipment ID:</strong>
								<span>{deliveryPlanItem.shipmentId}</span>
							</div> :
							deliveryPlanItem?.errorInfo?.message ?
								<div className={styles.routeItemError}>
									<strong style={{ marginRight: '4px', color: '#ff4d4f' }}>
								Error:
									</strong>
									<span>{deliveryPlanItem.errorInfo.message}</span>
								</div> :
								null
					}
				</div>
				<div className={styles.orderInfo}>
					<div className={styles.orderInfoRow}>
						<div className={styles.orderInfoHeading}>Order value</div>
						<div className={styles.orderInfoValue}>{getFormattedCurrency(totalAmount)}</div>
					</div>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						{
							deliveryPlanItem.recommendedVehicle &&
							<div className={styles.orderInfoRow}>
								<div className={styles.orderInfoHeading}>Vehicle</div>
								<div className={styles.orderInfoValue}>{deliveryPlanItem.recommendedVehicle.maxWeight} Tonnes {deliveryPlanItem.recommendedVehicle.cbm} CBM  <i>{deliveryPlanItem.recommendedVehicle.label}</i></div>
							</div>
						}
						{
							deliveryPlanItem.error &&
							<div className={styles.orderInfoRow}>
								<div className={styles.orderInfoHeading}>Vehicle</div>
								<div className={styles.routeError}>{deliveryPlanItem.error}</div>
							</div>
						}
						<div className={styles.orderInfoRow}>
							<div className={styles.orderInfoHeading}>Weight</div>
							<div className={styles.orderInfoValue}>
								{(totalWeightInTonnes || 0).toFixed(2)} Tonnes
								<span
									className={
										loadedWeight > 100 ? styles.overWeightIndicator : styles.weightIndicator
									}
								>
									({loadedWeight}% By Weight)
								</span>
							</div>
						</div>
						<div className={styles.orderInfoRow}>
							<div className={styles.orderInfoHeading}>Volume</div>
							<div className={styles.orderInfoValue}>
								{(totalVolumeInCbm || 0).toFixed(2)} CBM
								<span
									className={
										loadedVolume > 100 ? styles.overWeightIndicator : styles.weightIndicator
									}
								>
									({loadedVolume}% By Volume)
								</span>
							</div>
						</div>
					</div>
				</div>
				{
					currentVehicle &&
					<div className={styles.orderInfo}>
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							{
								deliveryPlanItem.recommendedVehicle &&
								<div className={styles.orderInfoRow}>
									<div className={styles.orderInfoHeading}>Vehicle</div>
									<div className={styles.orderInfoValue}>
										<span>{vehicle.plateNumber}</span>
										<span className={styles.vehicleInfo}>
											<Popover
												content={(
													<div>
														<div>
															<span className={styles.infoTitle}>Type:</span>
															<span className={styles.infoValue}>{getVehicleTypeLabel(vehicle.type)}</span>
														</div>
														<div>
															<span className={styles.infoTitle}>Actual Weight Capacity:</span>
															<span className={styles.infoValue}>{vehicle.maxWeightCapacity || 0}</span>
															<span className={styles.infoValue}>Tonnes</span>
														</div>
														<div>
															<span className={styles.infoTitle}>Actual Volume Capacity:</span>
															<span className={styles.infoValue}>{vehicle.maxVolumeCapacity || 0}</span>
															<span className={styles.infoValue}>CBM</span>
														</div>
													</div>
												)}
											>
												<InfoCircleFilled
													style={{
														color: 'teal',
														fontSize: '16px',
														cursor: 'pointer'
													}}
												/>
											</Popover>
										</span>
									</div>
								</div>
							}
							<div className={styles.orderInfoRow}>
								<div className={styles.orderInfoHeading}>Weight</div>
								<div className={styles.orderInfoValue}>
									{(totalWeightInTonnes || 0).toFixed(2)} Tonnes
									<span
										className={
											actualLoadedWeight > 100 ? styles.overWeightIndicator : styles.weightIndicator
										}
									>
										({actualLoadedWeight}% By Weight)
									</span>
								</div>
							</div>
							<div className={styles.orderInfoRow}>
								<div className={styles.orderInfoHeading}>Volume</div>
								<div className={styles.orderInfoValue}>
									{(totalVolumeInCbm || 0).toFixed(2)} CBM
									<span
										className={
											actualLoadedVolume > 100 ? styles.overWeightIndicator : styles.weightIndicator
										}
									>
										({actualLoadedVolume}% By Volume)
									</span>
								</div>
							</div>
						</div>
					</div>
				}
				{
					editableDeliveryPlanDetail && hoveredRouteId === deliveryPlanItem.id ?
						<div>
							<Button
								style={{ margin: '0px 16px' }}
								onClick={() => {
									setIsAddRoutePointsModalVisible(true)
									setSelectedDeliveryPlanItem(deliveryPlanItem)
								}}
								icon={<PlusOutlined />}
							>
								Add
							</Button>
						</div> : null
				}
				<div className={styles.travelInfoContainer}>
					{
						deliveryPlanItem.startLocation &&
						<div className={styles.orderInfoRow} style={{ alignSelf: 'flex-end' }}>
							<Tooltip
								title='Pick Up Location'
							>
								<div className={styles.badge} style={{ marginRight: 8 }}>
									{convertEnumToString(deliveryPlanItem.startLocation.type.toLowerCase())}
								</div>
							</Tooltip>
							{
								[LocationTypes.WAREHOUSE, LocationTypes.VIRTUAL_WAREHOUSE, LocationTypes.FACTORY].includes(deliveryPlanItem.startLocation.type) && !deliveryPlanItem.startLocation.deletedAt ?
									<Link href={`${paths.WAREHOUSES}/${deliveryPlanItem.startLocation.id}`}>
										<a className={styles.orderInfoValue} style={{ maxWidth: 300 }}>
											{`${deliveryPlanItem.startLocation.address}${deliveryPlanItem.startLocation.internalId ?
												` (${deliveryPlanItem.startLocation.internalId})` : ''}`}
										</a>
									</Link> :
									<div className={styles.orderInfoValue} style={{ maxWidth: 300 }}>
										{`${deliveryPlanItem.startLocation.address}${deliveryPlanItem.startLocation.internalId ? ` (${deliveryPlanItem.startLocation.internalId})` : ''}`}
									</div>
							}
						</div>
					}
					{renderTravelInfo(deliveryPlanItem)}
					<Assignees deliveryPlanItem={deliveryPlanItem} />
					<div className={styles.routeMenuContainer}>
						<div className={styles.internalNotes}>
							<InternalNotesPopover
								id={deliveryPlanItem.id}
								notes={deliveryPlanItem.internalNotes}
								onSave={updateInternalNotes}
							/>
						</div>
						<div className={styles.routeMenu}>
							{renderRouteMenu(deliveryPlanItem)}
						</div>
					</div>
				</div>
			</div>
		)
	}

	const renderDroppableRoutesList = (deliveryPlanItem) => {
		const {
			nonOverWeightedRows,
			nonOverVolumedRows
		} = deliveryPlanItem.metadata ? deliveryPlanItem.metadata : {}

		return (
			<Droppable
				droppableId={deliveryPlanItem.id}
			>
				{
					(provided, snapshot) => {
						return (
							<div
								ref={provided.innerRef}
								style={getDroppableStyle(snapshot.isDraggingOver)}
							>
								<Table
									style={{ marginBottom: '12px' }}
									loading={isLoading}
									columns={
										!editableDeliveryPlanDetail ?
											salesOrderColumns :
											[
												...salesOrderColumns,
												{
													title: 'Action',
													key: 'action',
													render: (salesOrder) => {
														return (
															<Tooltip
																title={
																	`This action will unassign this order (${salesOrder.internalId}) from the delivery plan.`
																}
															>
																<span
																	onClick={() => onRemoveRouteItem(deliveryPlanItem, salesOrder)}
																	className={styles.actionIcon}
																>
																	<DeleteOutlined />
																</span>
															</Tooltip>
														)
													}
												}
											]
									}
									dataSource={deliveryPlanItem.salesOrders}
									pagination={false}
									rowKey='id'
									size='small'
									components={{
										body: {
											row: (props) => renderDraggableBodyRow(props, deliveryPlanItem.salesOrders, formattedDeliveryPlanDetail.useWeight ? nonOverWeightedRows : nonOverVolumedRows)
										}
									}}
									rowSelection={
										editableDeliveryPlanDetail ?
											{
												preserveSelectedRowKeys: true,
												selectedRowKeys: selectedRowIds,
												onChange: (rowKeys) => onRowSelectChange(rowKeys, deliveryPlanItem)
											} : undefined
									}
								/>
								{provided.placeholder}
							</div>
						)
					}
				}
			</Droppable>
		)
	}

	const renderPickUpLocation = (order) => {
		return (
			<div className={styles.locationContainer}>
				<div className={styles.badge}>
					{convertEnumToString(order.pickUpLocation.type.toLowerCase())}
				</div>
				{
					[LocationTypes.WAREHOUSE, LocationTypes.VIRTUAL_WAREHOUSE, LocationTypes.FACTORY].includes(order.pickUpLocation.type) && !order.pickUpLocation.deletedAt ?
						<Link href={`${paths.WAREHOUSES}/${order.pickUpLocation.id}`}>
							<a className={styles.location}>
								{`${order.pickUpLocation.address}${order.pickUpLocation.internalId ?
												` (${order.pickUpLocation.internalId})` : ''}`}
							</a>
						</Link> :
						<div className={styles.location}>
							{`${order.pickUpLocation.address}${order.pickUpLocation.internalId ? ` (${order.pickUpLocation.internalId})` : ''}`}
						</div>
				}
			</div>
		)
	}

	const renderDroppableNonGeneratedOrdersList = () => {
		return (
			<Droppable
				droppableId={'non_generated'}
			>
				{
					(provided, snapshot) => {
						return (
							<div
								ref={provided.innerRef}
								style={getDroppableStyle(snapshot.isDraggingOver)}
							>
								<Table
									style={{ marginBottom: '12px' }}
									loading={isLoading}
									columns={[
										...salesOrderColumns.slice(0, 3),
										{
											title: 'Pick Up Location',
											key: 'pickUpLocation',
											render: renderPickUpLocation
										},
										...salesOrderColumns.slice(3)
									]}
									dataSource={nonGeneratedSalesOrders}
									pagination={false}
									rowKey='id'
									size='small'
									components={{
										body: {
											row: (props) => renderDraggableBodyRow(props, nonGeneratedSalesOrders, nonGeneratedSalesOrders.map(o => o.id))
										}
									}}
								/>
								{provided.placeholder}
							</div>
						)
					}
				}
			</Droppable>
		)
	}

	const renderDetails = () => {
		return (
			formattedDeliveryPlanDetail ?
				<div style={{ marginTop: 113 }}>
					<Row style={{ marginTop: '24px' }}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<div className={styles.id}>
								{`DP-${deliveryPlanDetail.shortId}`}
							</div>
							<Tooltip title='Update delivery plan status'>
								<Dropdown
									trigger={['click']}
									overlay={() => {
										return (
											<Menu>
												{
													modifiedStatusMenuItems.map(status => {
														return (
															<Menu.Item
																key={status}
																onClick={() => onUpdateStatus(status)}
																disabled={status === deliveryPlanDetail.status}
															>
																<Badge
																	text={getDeliveryPlanStatusLabel(status)}
																	color={DELIVERY_PLAN_STATUS_COLORS[status]}
																/>
															</Menu.Item>
														)
													})
												}
											</Menu>
										)
									}}
								>
									<Button
										loading={isUpdatingStatus}
										disabled={!permissions.editDeliveryPlans}
										type='primary'
										icon={<DownOutlined />}
										style={{
											marginLeft: 24,
											background: DELIVERY_PLAN_STATUS_COLORS[deliveryPlanDetail.status],
											borderColor: DELIVERY_PLAN_STATUS_COLORS[deliveryPlanDetail.status]
										}}
									>
										{getDeliveryPlanStatusLabel(deliveryPlanDetail.status)}
									</Button>
								</Dropdown>
							</Tooltip>
							<Tooltip
								title='Orders are sorted in first-in-last-out order and represents the most efficient way to load your vehicles.'
							>
								<div className={styles.lifoTag}>
									<img src='/img/checked-circle.svg' alt='checked icon' />
									<h3>FILO</h3>
								</div>
							</Tooltip>
						</div>
						<div style={{ display: 'flex', marginLeft: 'auto' }}>
							<Button
								style={{ margin: '0 6px' }}
								loading={isExportingPdf}
								onClick={onExportPdf}
								icon={<FilePdfOutlined />}
							>
								Export PDF
							</Button>
							<Button
								style={{ width: 98, margin: '0 6px' }}
								disabled={deliveryPlanDetail.status !== DELIVERY_PLAN_PENDING_STATUS}
								loading={isUpdating}
								type={editableDeliveryPlanDetail ? 'primary' : 'default'}
								onClick={() => editableDeliveryPlanDetail ? onSave() : onEdit()}
							>
								{
									editableDeliveryPlanDetail ? 'Save' : 'Edit'
								}
							</Button>
							{
								editableDeliveryPlanDetail ?
									<>
										<Button
											style={{ margin: '0 6px' }}
											onClick={() => setIsPickUpLocationModalVisible(true)}
											icon={<PlusOutlined />}
										>
											New Route
										</Button>
										<Button
											style={{ margin: '0 6px' }}
											onClick={onEditCancel}
										>
											Cancel
										</Button>
									</> : null

							}
						</div>
					</Row>
					<Col style={{ marginTop: '24px' }}>
						<Row>
							<div className={styles.statContainer}>
								<div className={styles.statTitle}>Delivery Date</div>
								<div className={styles.statValue}>{moment(formattedDeliveryPlanDetail.deliveryDate).format('MMM D, YYYY')}</div>
							</div>
							<div className={styles.statContainer}>
								<div className={styles.statTitle}>Order Count</div>
								<div className={styles.statValue}>{deliveryPlanDetail.salesOrders.length}</div>
							</div>
							<div className={styles.statContainer}>
								<div className={styles.statTitle}>Total Order Value</div>
								<div className={styles.statValue}>{getFormattedCurrency(totalAmount)}</div>
							</div>
							<div className={styles.statContainer}>
								<div className={styles.statTitle}>Total Weight (Tonnes)</div>
								<div className={styles.statValue}>{totalWeightInTonnes.toFixed(2)}</div>
							</div>
							<div className={styles.statContainer}>
								<div className={styles.statTitle}>Total Volume (CBM)</div>
								<div className={styles.statValue}>{totalVolumeInCbm.toFixed(2)}</div>
							</div>
							<div className={styles.statContainer}>
								<div className={styles.statTitle}>Customer Count</div>
								<div className={styles.statValue}>{distributorCount}</div>
							</div>
						</Row>
						<DragDropContext
							onBeforeCapture={onBeforeCapture}
							onDragEnd={onDragEnd}
						>
							{
								formattedDeliveryPlanDetail.deliveryPlanItems.map((deliveryPlanItem, index) => {
									return (
										<div
											key={deliveryPlanItem.id}
											style={{ marginTop: '36px' }}
											onMouseEnter={() => {
												setHoveredRouteId(deliveryPlanItem.id)
											}}
											onMouseLeave={() => {
												setHoveredRouteId(null)
											}}
										>
											{renderRouteHeaderInfo(deliveryPlanItem, index)}
											{renderDroppableRoutesList(deliveryPlanItem)}
										</div>
									)
								})
							}
							{
								nonGeneratedSalesOrders.length > 0 ?
									<div className={styles.nonGeneratedItems}>
										<div>
											<span className={styles.heading}>Unable to generate routes</span>
											<span>
												<Tooltip
													title='System is unable to generate the delivery plan route for the orders as the order&apos;s weight/volume exceeds the vehicle capacity'
												>
													<InfoCircleFilled
														style={{
															color: '#FF5959',
															fontSize: '16px',
															cursor: 'pointer',
															marginLeft: '6px'
														}}
													/>
												</Tooltip>
											</span>
										</div>
										{renderDroppableNonGeneratedOrdersList()}
									</div> : null
							}
						</DragDropContext>
					</Col>
				</div> :
				<div style={{ display: 'flex' }}>
					<Spin size='large' className={styles.spin} />
				</div>

		)
	}

	const renderLogs = () => {
		return (
			<div className={styles.logContainer}>
				{
					deliveryPlanHistory.length > 0 ?
						deliveryPlanHistory.map(deliveryPlanHistoryItem => {
							return (
								<div
									className={styles.deliveryPlanHistoryItem}
									key={deliveryPlanHistoryItem.id}
								>
									<div className={styles.deliveryPlanHistoryItemDate}>
										<div className={styles.deliveryPlanHistoryItemDate}>{moment(deliveryPlanHistoryItem.createdAt).format('D MMM, YYYY')}</div>
										<div className={styles.time}>{moment(deliveryPlanHistoryItem.createdAt).format('LT')}</div>
									</div>
									<div className={styles.messageContainer}>
										<div className={styles.message}>
											{deliveryPlanHistoryItem.message.en}
										</div>
										{
											deliveryPlanHistoryItem.customMessage ?
												<div className={styles.customMessage}>
													{deliveryPlanHistoryItem.customMessage}
												</div> : null
										}
									</div>
								</div>
							)
						}) :
						<Empty
							description='No logs available.'
							style={{ marginTop: '128px', fontSize: 24 }}
						/>
				}
			</div>
		)
	}

	return (
		<>
			<div className={styles.headerContainer}>
				<div className={styles.tabContainer}>
					{
						tabs.map(tab => {
							return (
								<HeaderTab
									key={tab.value}
									title={tab.title}
									isSelected={() => currentTab === tab.value}
									onClick={() => setCurrentTab(tab.value)}
								/>
							)
						})
					}
				</div>
				{
					loadingVehiclesDeliveryPlanId ?
						<div className={styles.requestProgress}>
							<div className={styles.bar} />
						</div> : null
				}
			</div>
			<Content>
				{
					currentTab === tabs[0].value &&
					renderDetails()
				}
				{
					currentTab === tabs[1].value &&
					renderLogs()
				}
			</Content>
			<div ref={pageBottomRef} />
			{
				deliveryPlanItemChallanUrl &&
				<iframe
					id='delivery-plan-item'
					style={{ display: 'none' }}
					src={deliveryPlanItemChallanUrl}
				/>
			}
			{
				isAddRoutePointsModalVisible ?
					<AddRoutePointsModal
						onCancel={() => setIsAddRoutePointsModalVisible(false)}
						deliveryPlanId={editableDeliveryPlanDetail.id}
						visible={isAddRoutePointsModalVisible}
						onAddClicked={addRoutePoints}
					/> : null
			}
			{
				isAssignDriverModalVisible ?
					<AssignDriverModal
						deliveryPlanItemId={selectedDeliveryPlanItem.id}
						visible={isAssignDriverModalVisible}
						onCancel={() => {
							setIsAssignDriverModalVisible(false)
							setSelectedDeliveryPlanItem(null)
						}}
					/> : null
			}
			{
				isAssignHelperModalVisible ?
					<AssignHelperModal
						deliveryPlanItemId={selectedDeliveryPlanItem.id}
						visible={isAssignHelperModalVisible}
						onCancel={() => {
							setIsAssignHelperModalVisible(false)
							setSelectedDeliveryPlanItem(null)
						}}
					/> : null
			}
			{
				isAssignVehicleModalVisible ?
					<AssignVehicleModal
						deliveryPlanItemId={selectedDeliveryPlanItem.id}
						deliveryPlanItemInternalId={selectedDeliveryPlanItem.internalId}
						vehicleType={selectedVehicleType}
						visible={isAssignVehicleModalVisible}
						onCancel={() => {
							setIsAssignVehicleModalVisible(false)
							setSelectedDeliveryPlanItem(null)
							setSelectedVehicleType(null)
						}}
					/> : null
			}
			{
				isAssignTransportVendorModalVisible ?
					<AssignTransportVendorModal
						deliveryPlan={deliveryPlanDetail}
						deliveryPlanItemId={selectedDeliveryPlanItem.id}
						deliveryPlanItemInternalId={selectedDeliveryPlanItem.internalId}
						assignedTransportVendors={selectedDeliveryPlanItem.transportVendors}
						visible={isAssignTransportVendorModalVisible}
						onCancel={() => {
							setIsAssignTransportVendorModalVisible(false)
							setSelectedDeliveryPlanItem(null)
						}}
					/> : null
			}
			{
				isUpdateVehicleModalVisible ?
					<UpdateVehicleModal
						deliveryPlanItemId={selectedDeliveryPlanItem.id}
						visible={isUpdateVehicleModalVisible}
						vehicleType={selectedVehicleType}
						onCancel={() => {
							setIsUpdateVehicleModalVisible(false)
							setSelectedDeliveryPlanItem(null)
						}}
					/> : null
			}
			{
				isPickUpLocationModalVisible ?
					<StartLocationModal
						onCancel={() => setIsPickUpLocationModalVisible(false)}
						visible={isPickUpLocationModalVisible}
						onUseLocation={location => {
							setIsPickUpLocationModalVisible(false)
							onAddNewRoute(location)
						}}
					/> : null
			}
			<Modal
				width={516}
				title="Update Delivery Plan's Status"
				okText='Yes'
				isLoading={isUpdatingStatus}
				visible={isCompleteModalVisible}
				onOk={async () => {
					await updateDeliveryPlanStatus(orderStatuses.SALES_ORDER_DELIVERED_STATUS)
					setIsCompleteModalVisible(false)
				}}
				onCancel={() => setIsCompleteModalVisible(false)}
			>
				{`Are you sure you want to override the status of the delivery Plan DP-${deliveryPlanDetail?.shortId} to Completed?`}
			</Modal>
			<Modal
				width={516}
				title="Update Delivery Plan's Status"
				okText='Yes'
				isLoading={isUpdatingStatus}
				visible={isCancelModalVisible}
				onOk={async () => {
					await updateDeliveryPlanStatus(orderStatuses.SALES_ORDER_CANCELLED_STATUS, cancellationReason)
					setIsCancelModalVisible(false)
				}}
				onCancel={() => setIsCancelModalVisible(false)}
			>
				<>
						Are you sure you want to override the status of the delivery Plan DP-{deliveryPlanDetail?.shortId} to Cancelled?
					<TextInput
						containerStyle={{ marginTop: '16px' }}
						title='Cancelled Reason'
						onChange={(e) => setCancellationReason(e.target.value)}
					/>
				</>
			</Modal>
		</>
	)
}

DeliveryPlanDetailsPage.getLayout = function getLayout (page) {
	return (
		<Page
			parentTitle='Delivery Plans'
			parentLink={paths.DELIVERY_PLANS}
		>
			{page}
		</Page>
	)
}

export default DeliveryPlanDetailsPage
