import { Button, Modal, Spin } from 'antd'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import ReactToPrint from 'react-to-print'
import { DateTypes, Invoice, LabelSizes, PosInvoice, POSInvoiceTypes, ProductColumns, RowTypes } from '../../pages/invoice'
import { Invoices } from '../../services/api/firebase'
import { SalesOrders } from '../../services/api/sales-orders'
import { InvoiceTypes } from '../../utils/constants'
import { SALES_ORDER_COMPLETED_STATUS } from '../../pages/sales-orders'
import { VolumeUnits } from '../../utils/units'
import { isNotEmpty } from '../../utils'
import moment from 'moment'

const PrintSalesOrderInvoicesModal = ({ visible, salesOrderIds, onCancel }) => {
	const { userProfile, companyDetails } = useSelector(state => state.authReducer)
	const [invoiceData, setInvoiceData] = useState([])
	const [message, setMessage] = useState('Rendering Invoices....')
	const [hasError, setHasError] = useState(false)
	const [posType, setPosType] = useState(POSInvoiceTypes.POS_STICKER)
	const [labelSize, setLabelSize] = useState(LabelSizes.FOUR_BY_TWO)
	const [isUpdating, setIsUpdating] = useState(false)
	const printableRef = useRef()
	const printRef = useRef()
	const invoiceType = useMemo(() => {
		if (companyDetails) {
			return companyDetails.documentTemplates?.invoiceType ?? InvoiceTypes.GENERAL
		} else {
			return null
		}
	}, [companyDetails])

	useEffect(() => {
		if (userProfile) {
			getInvoiceData()
		}
	}, [userProfile])


	const getInvoiceData = async () => {
		const { company } = userProfile
		const invoiceConfiguration = await Invoices.listenToInvoiceConfigurationOnce(company.id)
		const mainInvoiceData = {}
		mainInvoiceData.header = company.name
		if (invoiceConfiguration) {
			mainInvoiceData.header = invoiceConfiguration.header || mainInvoiceData.header
			mainInvoiceData.subHeader = invoiceConfiguration.subHeader
			mainInvoiceData.address = invoiceConfiguration.address || company.address
			mainInvoiceData.email = invoiceConfiguration.email || userProfile.email
			mainInvoiceData.facebook = invoiceConfiguration.facebook
			mainInvoiceData.website = invoiceConfiguration.website
			mainInvoiceData.info1Title = invoiceConfiguration.info1Title
			mainInvoiceData.info1Text = invoiceConfiguration.info1Text
			mainInvoiceData.info2Title = invoiceConfiguration.info2Title
			mainInvoiceData.info2Text = invoiceConfiguration.info2Text
			mainInvoiceData.info3Title = invoiceConfiguration.info3Title
			mainInvoiceData.info3Text = invoiceConfiguration.info3Text
			mainInvoiceData.info4Title = invoiceConfiguration.info4Title
			mainInvoiceData.info4Text = invoiceConfiguration.info4Text
			mainInvoiceData.attachmentUrl = invoiceConfiguration.attachmentUrl
			mainInvoiceData.productSpecification = invoiceConfiguration.productSpecification || 'weight'
			mainInvoiceData.additionalContactInfo = invoiceConfiguration.additionalContactInfo
			if (invoiceConfiguration.isFooterEnabled !== null && invoiceConfiguration.isFooterEnabled !== undefined) {
				mainInvoiceData.isFooterEnabled = invoiceConfiguration.isFooterEnabled
			} else {
				mainInvoiceData.isFooterEnabled = true
			}
			mainInvoiceData.rowType = invoiceConfiguration.rowType || RowTypes.FIXED
			mainInvoiceData.rowSize = invoiceConfiguration.rowSize || 8
			mainInvoiceData.dateType = invoiceConfiguration.dateType || DateTypes.SHIPPING
			const posType = invoiceConfiguration.posType || POSInvoiceTypes.POS_STICKER
			setPosType(posType)
			mainInvoiceData.posType = posType
			const labelSize = invoiceConfiguration.labelSize || LabelSizes.FOUR_BY_TWO
			setLabelSize(labelSize)
			mainInvoiceData.labelSize = labelSize
			mainInvoiceData.rotation = invoiceConfiguration.rotation || 0
			mainInvoiceData.productColumn = invoiceConfiguration.productColumn || ProductColumns.NAME
			mainInvoiceData.isItemNameColumnEnabled = isNotEmpty(invoiceConfiguration.isItemNameColumnEnabled) ? invoiceConfiguration.isItemNameColumnEnabled : true
			mainInvoiceData.isSkuColumnEnabled = isNotEmpty(invoiceConfiguration.isSkuColumnEnabled) ? invoiceConfiguration.isSkuColumnEnabled : false
			mainInvoiceData.isWeightColumnEnabled = isNotEmpty(invoiceConfiguration.isWeightColumnEnabled) ? invoiceConfiguration.isWeightColumnEnabled : true
			mainInvoiceData.isVolumeColumnEnabled = isNotEmpty(invoiceConfiguration.isVolumeColumnEnabled) ? invoiceConfiguration.isVolumeColumnEnabled : false
		} else {
			mainInvoiceData.isItemNameColumnEnabled = true
			mainInvoiceData.isWeightColumnEnabled = true
		}
		const { companyDetails } = company
		if (companyDetails.logoUrl) {
			mainInvoiceData.logoUrl = companyDetails.logoUrl
		} else {
			mainInvoiceData.logoUrl = '/img/logo.svg'
		}
		await getSalesOrders(mainInvoiceData)
	}

	const getSalesOrders = async (mainInvoiceData) => {
		try {
			const response = await SalesOrders.multiple(salesOrderIds)
			const salesOrders = response.data
			if (salesOrders) {
				const invoiceData = []
				salesOrders.sort((a, b) => {
					if (a.createdAt < b.createdAt) {
						return -1
					}
					if (a.createdAt > b.createdAt) {
						return 1
					}
					return 0
				})
				for (const salesOrder of salesOrders) {
					if (salesOrder.isStockTransfer) {
						continue
					}
					const invoice = mapInvoiceData(salesOrder)
					invoiceData.push({
						...mainInvoiceData,
						...invoice
					})
				}
				if (invoiceData.length === 0) {
					setHasError(true)
					setMessage('No sales orders to print invoices for.')
				} else {
					setInvoiceData(invoiceData)
					setTimeout(() => {
						printRef.current.handleClick()
					}, 1500)
				}
			}
		} catch (e) {
			console.error(e)
			setHasError(true)
			setMessage('An error has occurred when trying to print the selected orders.')
			onCancel()
		}
	}

	const mapInvoiceData = (data) => {
		const salesOrderItems = data.salesOrderItems || []
		let subTotalAmount = 0
		const invoiceItems = salesOrderItems.map((salesOrderItem, i) => {
			const name = salesOrderItem.product ? salesOrderItem.product.name : '-'
			const sku = salesOrderItem.product ? salesOrderItem.product.sku : '-'
			const weightSpec = salesOrderItem.product ? salesOrderItem.product.productSpecifications.find(spec => spec.specificationKey === 'weight') : undefined
			let weight = '-'
			if (weightSpec) {
				weight = `${weightSpec.value} ${weightSpec.unit}`
			}
			const volumeSpec = salesOrderItem.product ? salesOrderItem.product.productSpecifications.find(spec => spec.specificationKey === 'volume') : undefined
			let volume = '-'
			if (volumeSpec) {
				volume = `${volumeSpec.value} ${volumeSpec.unit === VolumeUnits.M3 ? 'mᶟ' : volumeSpec.unit}`
			}
			const price = +salesOrderItem.price
			let quantity = salesOrderItem.packageQuantity > 0 ? salesOrderItem.packageQuantity : salesOrderItem.quantity
			if (data.status.toLowerCase() === SALES_ORDER_COMPLETED_STATUS) {
				quantity = salesOrderItem.deliveredPackageQuantity > 0 ? salesOrderItem.deliveredPackageQuantity : salesOrderItem.deliveredQuantity
			}
			const amount = price * quantity
			const discountAmount = +salesOrderItem.discount > 0 ? (price * +salesOrderItem.discount / 100) * quantity : +salesOrderItem.discountAmount
			subTotalAmount += amount - (discountAmount || 0)
			return {
				sl: i + 1,
				name,
				sku,
				weight,
				volume,
				price,
				quantity,
				amount,
				discountAmount
			}
		})
		let totalPayments = data.transactions.reduce((acc, transaction) => {
			const amount = +transaction.amount
			return acc + amount
		}, 0)
		const additionalInvoiceFees = []
		if (totalPayments === 0) {
			totalPayments = +data.distributorAdvancePayment || 0
		}
		if (totalPayments > 0) {
			additionalInvoiceFees.push({
				name: 'Payment',
				amount: totalPayments
			})
		}
		const discountAmount = +data.discount > 0 ? (subTotalAmount * +data.discount) / 100 : +data.discountAmount
		const advanceAmount = +data.distributorAdvancePayment
		const totalAmount = subTotalAmount - discountAmount + +data.deliveryCharge - totalPayments
		const recipientAddress = !data.distributor || !data.location ? '' : data.distributor?.name !== data.location?.label ? data.location?.label || data.location?.address : data.location?.address
		const trackingId = data.deliveryConsignment?.id || data.deliveryConsignment?.trackingCode || data.deliveryOrderId || data.deliveryTrackingId
		return {
			recipientName: data.distributor ? data.distributor.name : '',
			recipientPhone: data.distributor ? data.distributor.phone : '',
			recipientAddress,
			date: data.orderDate,
			orderDate: data.orderDate,
			creationDate: data.createdAt,
			referenceNumber: data.internalId,
			trackingId,
			invoiceItems,
			subTotalAmount,
			discount: data.discount,
			discountAmount,
			deliveryCharge: +data.deliveryCharge,
			deliveryPartner: data.deliveryPartner,
			advanceAmount,
			totalAmount,
			invoicePayments: additionalInvoiceFees,
			additionalNotes: data.additionalNotes
		}
	}

	const onAfterPrint = async () => {
		try {
			setIsUpdating(true)
			await SalesOrders.updatePrintStatuses(salesOrderIds)
		} catch (e) {
			console.error(e)
		} finally {
			setIsUpdating(false)
		}
		onCancel()
	}

	const pageStyle = useMemo(() => {
		if (invoiceType === InvoiceTypes.POS) {
			if (posType === POSInvoiceTypes.THREE_IN_ROLLING_PAPER) {
				return '@page { size: 3in auto }'
			} else {
				switch (labelSize) {
					case LabelSizes.FOUR_BY_TWO:
						return '@page { size: 2in 4in }'
					case LabelSizes.FOUR_BY_THREE:
						return '@page { size: 3in 4in }'
					case LabelSizes.FOUR_BY_FOUR:
						return '@page { size: 4in }'
					case LabelSizes.FOUR_BY_SIX:
						return '@page { size: 6in 4in }'
					default:
						return '@page { size: 2in 4in }'
				}
			}
		} else {
			return undefined
		}
	}, [labelSize, posType, invoiceType])

	const renderModalFooter = () => {
		return (
			<div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
				{
					hasError &&
					<Button
						loading={isUpdating}
						onClick={onCancel}
					>
						Cancel
					</Button>
				}
				{
					!hasError &&
					<Button
						type='primary'
						loading={true}
					>
						Printing...
					</Button>
				}
			</div>
		)
	}

	return (
		<Modal
			title='Print Invoices'
			visible={visible}
			onCancel={onCancel}
			footer={renderModalFooter()}
		>
			<div style={{ display: 'flex', alignItems: 'center', justifyItems: 'center', justifyContent: 'center', width: '100%' }}>
				{
					!hasError && <Spin style={{ marginRight: 12 }} />
				}
				{message}
			</div>
			<div ref={printableRef}>
				{
					invoiceData.map((data, index) => {
						return (
							<>
								{
									invoiceType === InvoiceTypes.GENERAL &&
									<Invoice
										key={index}
										visible={false}
										logoUrl={data.logoUrl}
										header={data.header}
										subHeader={data.subHeader}
										recipientName={data.recipientName}
										recipientPhone={data.recipientPhone}
										recipientAddress={data.recipientAddress}
										date={data.date}
										orderDate={data.orderDate}
										creationDate={data.creationDate}
										referenceNumber={data.referenceNumber}
										trackingId={data.trackingId}
										invoiceItems={data.invoiceItems}
										subTotalAmount={data.subTotalAmount}
										discount={data.discount}
										discountAmount={data.discountAmount}
										deliveryCharge={data.deliveryCharge}
										deliveryPartner={data.deliveryPartner}
										totalAmount={data.totalAmount}
										advanceAmount={data.advanceAmount}
										invoicePayments={data.invoicePayments}
										additionalNotes={data.additionalNotes}
										email={data.email}
										address={data.address}
										website={data.website}
										facebook={data.facebook}
										info1Title={data.info1Title}
										info1Text={data.info1Text}
										info2Title={data.info2Title}
										info2Text={data.info2Text}
										info3Title={data.info3Title}
										info3Text={data.info3Text}
										info4Title={data.info4Title}
										info4Text={data.info4Text}
										attachmentUrl={data.attachmentUrl}
										productSpecification={data.productSpecification}
										additionalContactInfo={data.additionalContactInfo}
										rowType={data.rowType}
										rowSize={data.rowSize}
										dateType={data.dateType}
										isFooterEnabled={data.isFooterEnabled}
										isItemNameColumnEnabled={data.isItemNameColumnEnabled}
										isSkuColumnEnabled={data.isSkuColumnEnabled}
										isWeightColumnEnabled={data.isWeightColumnEnabled}
										isVolumeColumnEnabled={data.isVolumeColumnEnabled}
									/>
								}
								{
									invoiceType === InvoiceTypes.POS &&
									<PosInvoice
										key={index}
										visible={false}
										header={data.header}
										subHeader={data.subHeader}
										posType={data.posType}
										labelSize={data.labelSize}
										productColumn={data.productColumn}
										rotation={data.rotation}
										recipientName={data.recipientName}
										recipientPhone={data.recipientPhone}
										recipientAddress={data.recipientAddress}
										additionalNotes={data.additionalNotes}
										dateType={data.dateType}
										date={data.date}
										orderDate={data.orderDate}
										creationDate={data.creationDate}
										referenceNumber={data.referenceNumber}
										trackingId={data.trackingId}
										invoiceItems={data.invoiceItems}
										subTotalAmount={data.subTotalAmount}
										advanceAmount={data.advanceAmount}
										discount={data.discount}
										discountAmount={data.discountAmount}
										deliveryCharge={data.deliveryCharge}
										deliveryPartner={data.deliveryPartner}
										totalAmount={data.totalAmount}
										invoicePayments={data.invoicePayments}
										isItemNameColumnEnabled={data.isItemNameColumnEnabled}
										isSkuColumnEnabled={data.isSkuColumnEnabled}
									/>
								}
							</>
						)
					})
				}
			</div>
			<ReactToPrint
				ref={printRef}
				content={() => printableRef.current}
				onAfterPrint={onAfterPrint}
				pageStyle={pageStyle}
				documentTitle={`sales-orders_selected-${invoiceData?.length || ''}_${moment().format('MMM_DD_YYYY')}`}
			/>
		</Modal>
	)
}

export default PrintSalesOrderInvoicesModal
