import { destroy, get, post, patch } from './index'
import moment from 'moment'

export const Inventory = {
	indexInventory: ({
		page = 0,
		searchTerm,
		locationId,
		updatedFrom,
		updatedTo,
		minQuantity,
		maxQuantity
	} = {}) =>
		get('/inventory', {
			params: {
				page,
				searchTerm,
				locationId,
				updatedFrom,
				updatedTo,
				minQuantity,
				maxQuantity
			}
		}),
	singleWarehouse: (id) => get(`/inventory/warehouses/${id}`),
	fetchSalesOrderInventory: (id) => get(`/inventory/sales-orders/${id}`),
	bulkUpdateInventory: ({
		inventory = []
	} = {}) =>
		post('/inventory', {
			inventory
		}),
	bulkUpdateAdvancedInventory: ({
		inventoryItemsDto
	} = { inventoryItemsDto: [] }) =>
		patch('/inventory/advanced', {
			inventoryItemsDto
		}),
	syncProcessingStock: (cid) =>
		patch(`/inventory/${cid}/processing-stock`),
	indexInventoryProducts: ({
		page = 0,
		searchTerm,
		locationId,
		updatedFrom,
		updatedTo,
		createdFrom,
		createdTo,
		serialNumber,
		palletId,
		isInStock,
		packCode,
		sku,
		isPalleted,
		isOrdered,
		distinctFieldName,
		skus,
		blocked
	} = {}) =>
		get('/inventory/inventory-items', {
			params: {
				page,
				searchTerm,
				locationId,
				updatedFrom,
				updatedTo,
				isPalleted,
				distinctFieldName,
				createdFrom,
				createdTo,
				serialNumber,
				palletId,
				isInStock,
				packCode,
				isOrdered,
				sku,
				skus,
				blocked
			}
		}),
	indexInventoryOnHandStock: ({
		page = 0,
		searchTerm,
		updatedFrom,
		updatedTo,
		createdFrom,
		createdTo,
		serialNumber,
		palletId,
		packCode,
		sku,
		skus,
		shifts,
		packCodes,
		machineCodes,
		shelfLives,
		facilities,
		sortInTransitQuantity,
		sortOnHandStockQuantity
	} = {}) =>
		get('/inventory/on-hand-stock', {
			params: {
				page,
				searchTerm,
				updatedFrom,
				updatedTo,
				createdFrom,
				createdTo,
				serialNumber,
				palletId,
				packCode,
				sku,
				skus,
				shifts,
				packCodes,
				machineCodes,
				shelfLives,
				facilities,
				sortInTransitQuantity,
				sortOnHandStockQuantity
			}
		}),
	indexOnHandStockFilterOptions: ({
		page = 0,
		searchTerm,
		distinctFieldName,
		shelfLife
	} = {}) =>
		get('/inventory/on-hand-stock/filter-options', {
			params: {
				page,
				searchTerm,
				distinctFieldName,
				shelfLife
			}
		}),
	indexOrderMovementFilterOptions: ({
		page = 0,
		searchTerm,
		distinctFieldName,
		shelfLife,
		sku,
		destinationLocation,
		sourceLocation,
		orderNumber
	} = {}) =>
		get('/inventory/order-movement/filter-options', {
			params: {
				page,
				searchTerm,
				distinctFieldName,
				shelfLife,
				sourceLocation,
				destinationLocation,
				sku,
				orderNumber
			}
		}),
	indexInventoryOrderMovement: ({
		page = 0,
		createdFrom,
		createdTo,
		skus,
		shifts,
		packCodes,
		machineCodes,
		shelfLives,
		dropOffLocationIds,
		pickUpLocationIds,
		orderNumbers,
		isOrderDelivered,
		orderTypes
	} = {}) =>
		get('/inventory/order-movement', {
			params: {
				page,
				createdFrom,
				createdTo,
				skus,
				shifts,
				packCodes,
				machineCodes,
				shelfLives,
				dropOffLocationIds,
				pickUpLocationIds,
				orderNumbers,
				isOrderDelivered,
				orderTypes
			}
		}),
	downloadStockCsv: async ({ productType } = {}) => {
		const response = await get('/inventory/stock/csv', {
			responseType: 'blob',
			params: {
				productType
			}
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		link.download = `stock_${moment(new Date()).format('YYYY-MM-DD')}.csv`
		link.click()
		link.remove()
	},
	downloadOnHandStockCsv: async (params) => {
		const response = await get('/inventory/on-hand-stock/csv', {
			responseType: 'blob',
			params: {
				...params
			}
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		link.download = `on_hand_stock_${moment(new Date()).format('YYYY-MM-DD')}.csv`
		link.click()
		link.remove()
	},
	downloadOrderMovementCsv: async (params) => {
		const response = await get('/inventory/order-movement/csv', {
			responseType: 'blob',
			params: {
				...params
			}
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		link.download = `order_movement_${moment(new Date()).format('YYYY-MM-DD')}.csv`
		link.click()
		link.remove()
	},
	downloadStockMonitoringCsv: async (params) => {
		const response = await get('/inventory/stock-monitoring/csv', {
			responseType: 'blob',
			params: {
				...params
			}
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		link.download = `stock_monitoring_by_${params.bySku ?
			'sku' : 'location'}_${moment(new Date()).format('YYYY-MM-DD')}.csv`
		link.click()
		link.remove()
	},
	fetchReferenceNumber: (workCenter) => get('/inventory/inventory-items/reference-number', {
		params: {
			machineCode: workCenter
		}
	}),
	takeReferenceNumber: ({
		machineCode,
		count
	}) => post('/inventory/inventory-items/reference-number', {
		machineCode,
		count
	}),
	extendShelfLife: ({
		shelfLife,
		inventoryProductItemIds
	}) => patch('/inventory/inventory-items/shelf-life', {
		shelfLife,
		inventoryProductItemIds
	}),
	blockInventoryProducts: ({
		blocked,
		serialNumbers
	}) => patch('/inventory/inventory-items/blocked', {
		blocked,
		serialNumbers
	}),
	createInventoryProduct: (data) =>
		post('/inventory/inventory-items', data),
	fetchOrderedInventoryProductItems: (id, { page = 0, searchTerm } = {}) =>
		get(`/inventory/products/${id}/inventory-items`, {
			params: {
				page,
				searchTerm
			}
		}),
	fetchInventoryStockMonitoring: ({
		page = 0,
		bySku,
		byLocation,
		skus,
		facilities,
		shelfLifeRangeKey,
		shelfLives
	} = {}) =>
		get('/inventory/stock-monitoring', {
			params: {
				page,
				byLocation,
				bySku,
				skus,
				facilities,
				shelfLives,
				shelfLifeRangeKey
			}
		}),
	fetchInventoryProductItem: serialNumber =>
		get(`/inventory/inventory-items/${serialNumber}`),
	deleteInventoryProductItem: serialNumber =>
		destroy(`/inventory/inventory-items/${serialNumber}`),
	fetchInventoryLogs: ({
		page,
		searchTerm,
		startDate,
		endDate,
		locationId,
		productType,
		updatedBy,
		quantityChangeType
	} = {}) =>
		get('/inventory/inventory-items/logs', {
			params: {
				page,
				searchTerm,
				startDate,
				endDate,
				locationId,
				productType,
				updatedBy,
				quantityChangeType
			}
		}),
	fetchInventoryBatchItems: ({
		page,
		searchTerm,
		locationId,
		productIds,
		excludeShortageQuantity,
		orderByField,
		sortOrder
	} = {}) => {
		return get('/inventory/inventory-items/batches', {
			params: {
				page,
				searchTerm,
				locationId,
				productIds,
				excludeShortageQuantity,
				orderByField,
				sortOrder
			}
		})
	},
	downloadInventoryLogsCSV: async (params) => {
		const response = await get('/inventory/inventory-items/logs/csv', {
			responseType: 'blob',
			params: {
				...params,
				timezone: moment.tz.guess()
			}
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		link.download = `Inventory Logs_${moment(new Date()).format('YYYY-MM-DD')}.csv`
		link.click()
		link.remove()
	}
}
