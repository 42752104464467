import { get, post, destroy, patch } from './index'

export const DeliveryPlans = {
	index: ({
		page = 0,
		status,
		searchTerm,
		fromDate,
		toDate,
		minSalesOrderCount,
		maxSalesOrderCount,
		minOrderValue,
		maxOrderValue,
		minDistributorCount,
		maxDistributorCount,
		startLocationId,
		helperId,
		driverId
	} = {}) =>
		get('/delivery-plans', {
			params: {
				page,
				status,
				searchTerm,
				fromDate,
				toDate,
				minSalesOrderCount,
				maxSalesOrderCount,
				minOrderValue,
				maxOrderValue,
				minDistributorCount,
				maxDistributorCount,
				startLocationId,
				helperId,
				driverId
			}
		}),
	single: (id, {
		accessToken,
		startLocationId
	} = {}) =>
		get(`/delivery-plans/${id}`, {
			headers: {
				authorization: accessToken
			},
			params: {
				startLocationId
			}
		}),
	create: (params) =>
		post('/delivery-plans', params),
	update: (id, {
		status,
		cancellationReason
	} = {}) =>
		patch(`/delivery-plans/${id}`, {
			status,
			cancellationReason
		}),
	updateDeliveryPlanSalesOrders: (id, {
		salesOrderIds
	} = {}) =>
		patch(`/delivery-plans/${id}/sales-orders`, {
			salesOrderIds
		}),
	updateDeliveryPlanRoutes: (id, {
		routes,
		vehicles,
		salesOrderIds
	} = {}) =>
		patch(`/delivery-plans/${id}/routes`, {
			routes,
			vehicles,
			salesOrderIds
		}),
	updateDeliveryPlanItem: (id, params) =>
		patch(`/delivery-plans/delivery-plan-items/${id}`, params),
	updateDeliveryPlanItemStatus: (id, params) =>
		patch(`/delivery-plans/delivery-plan-items/${id}/status`, params),
	remove: (id) =>
		destroy(`/delivery-plans/${id}`),
	regenerateDeliveryPlan: (id) =>
		post(`/delivery-plans/${id}/regenerate`),
	singleDeliveryPlanItem: (id, {
		accessToken
	} = {}) =>
		get(`/delivery-plans/delivery-plan-items/${id}`, {
			headers: {
				authorization: accessToken
			}
		}),
	updateVehicleAssignmentRequestStatus: (requestId, params) =>
		patch(`/requests/${requestId}/status`, params),
	getTripReplay: ({ tripId, driverId, helperId, tripType } = {}) =>
		get(`/delivery-plans/delivery-plan-items/${tripId}/trip-replay`, {
			params: {
				tripType,
				driverId,
				helperId
			}
		})
}
