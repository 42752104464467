import moment from 'moment'
import { get, post, destroy, patch } from './index'

export const Products = {
	index: (
		{
			searchTerm,
			page = 0,
			type,
			category,
			subCategory1,
			subCategory2,
			subCategory3,
			subCategory4,
			subCategory5,
			minPrice,
			maxPrice,
			inventory,
			locationId,
			includeInventory,
			hasRawMaterial,
			deleted,
			all,
			includeVariants,
			vendorId,
			skipEmptyVendorMapping,
			excludeBase,
			excludeVariants,
			excludeSimple,
			includeInventoryBatchItems,
			parentProductId,
			attributes
		} = {},
		abortSignal
	) =>
		get('/products', {
			params: {
				searchTerm,
				page,
				type,
				category,
				subCategory1,
				subCategory2,
				subCategory3,
				subCategory4,
				subCategory5,
				minPrice,
				maxPrice,
				inventory,
				locationId,
				includeInventory,
				hasRawMaterial,
				deleted,
				all,
				includeVariants,
				vendorId,
				skipEmptyVendorMapping,
				excludeBase,
				excludeVariants,
				excludeSimple,
				includeInventoryBatchItems,
				parentProductId,
				attributes
			},
			signal: abortSignal
		}),
	single: (id, {
		inventory,
		packages,
		variants
	} = {}) =>
		get(`/products/${id}`, {
			params: {
				inventory,
				packages,
				variants
			}
		}),
	multiple: (ids, inventory = false, abortSignal) =>
		get('/products/multiple', {
			params: {
				ids,
				inventory
			},
			signal: abortSignal
		}),
	create: (params) =>
		post('/products', params),
	update: (id, params) =>
		patch(`/products/${id}`, params),
	getMetrics: (type) => get('/products/metrics', {
		params: {
			type
		}
	}),
	remove: (id) =>
		destroy(`/products/${id}`),
	fetchCategories: ({ industry, vendorId, skipEmptyVendorMapping, rawMaterialsOnly, finishedGoodsOnly } = {}) =>
		get('/products/categories', {
			params: {
				industry,
				vendorId,
				skipEmptyVendorMapping,
				rawMaterialsOnly,
				finishedGoodsOnly
			}
		}),
	createCategories: ({ categories } = { categories: [] }) =>
		post('/products/categories', { categories }),
	updateCategories: (categories = []) =>
		patch('/products/categories', { categories }),
	fetchSalesOrderItems: (id, { page = 0, salesOrderStatus, searchTerm } = {}) =>
		get(`/products/${id}/sales-orders`, {
			params: {
				page,
				salesOrderStatus,
				searchTerm
			}
		}),
	fetchPurchaseOrderItems: (id, { page, purchaseOrderStatus, searchTerm } = { page: 0 }) =>
		get(`/products/${id}/purchase-orders`, {
			params: {
				page,
				purchaseOrderStatus,
				searchTerm
			}
		}),
	bulkUpdatePrices: async ({
		products = []
	} = {}) =>
		patch('/products/prices', {
			products
		}),
	bulkToggleStatuses: async ({
		productsIds = []
	} = {}) =>
		patch('/products/statuses', {
			productsIds
		}),
	fetchSpecifications: ({ type, isDefault, types }) =>
		get('/products/specifications', {
			params: {
				type,
				isDefault,
				types
			}
		}),
	createSpecification: (params) =>
		post('/products/specifications', params),
	createSpecifications: (params) =>
		post('/products/specifications/multiple', params),
	updateSpecification: (key, params) =>
		patch(`/products/specifications/${key}`, params),
	topSeller: ({
		fromDate,
		toDate,
		status,
		variant,
		locationIds
	} = {}) =>
		get('/products/top-sellers', {
			params: {
				fromDate,
				toDate,
				status,
				variant,
				locationIds
			}
		}),
	downloadTopSellerCsv: async ({
		fromDate,
		toDate,
		status,
		variant
	} = {}) => {
		const response = await get('/products/top-sellers/csv', {
			responseType: 'blob',
			params: {
				fromDate,
				toDate,
				status,
				variant
			}
		})
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(response.data)
		link.download = `top_selling_products_${moment(new Date()).format('YYYY-MM-DD')}.csv`
		link.click()
		link.remove()
	},
	fetchAvailableQuantityMetrics: ({ locationIds, productIds } = {}) =>
		get('/products/available-metrics', {
			params: {
				locationIds,
				productIds
			}
		}),
	updateVariants: (id, { ids }) =>
		patch(`/products/${id}/variants`, {
			ids
		}),
	updateVendorMapping: (id, { vendorIds } = { vendorIds: [] }) =>
		post(`/products/update-vendor-mapping/${id}`, { vendorIds }),
	fetchSalesOrderAnalytics: (id, { filterBy, interval, timezone } = { interval: 'daily', timezone: 'Asia/Dhaka' }) =>
		get(`/products/${id}/analytics/sales-orders`, {
			params: {
				filterBy,
				interval,
				timezone
			}
		}),
	downloadOrderCSV: async (id, { fromDate, toDate }) => {
		const response = await get(`/products/${id}/order-summary/csv`, {
			responseType: 'blob',
			params: {
				fromDate,
				toDate,
				timezone: moment.tz.guess()
			}
		})
		const binaryData = [response.data]
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/csv' }))
		const fileName = `order_summary_${moment(fromDate).format('MMM_DD_YYYY')}-${moment(toDate).format('MMM_DD_YYYY')}.csv`
		link.download = fileName
		link.click()
		link.remove()
	},
	downloadSalesCSV: async (id, { fromDate, toDate }) => {
		const response = await get(`/products/${id}/sales/csv`, {
			responseType: 'blob',
			params: {
				fromDate,
				toDate,
				timezone: moment.tz.guess()
			}
		})
		const binaryData = [response.data]
		const link = document.createElement('a')
		link.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/csv' }))
		const fileName = `sales_report_${moment(fromDate).format('MMM_DD_YYYY')}-${moment(toDate).format('MMM_DD_YYYY')}.csv`
		link.download = fileName
		link.click()
		link.remove()
	}
}
