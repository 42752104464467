import { capitalizeWord, convertEnumToString } from '.'

export const ReportTypes = {
	ORDER_FULFILLMENT: 'ORDER_FULFILLMENT',
	PENDING_ORDERS: 'PENDING_ORDERS',
	TRACEABILITY: 'TRACEABILITY',
	NATIONAL_ON_HAND_STOCK: 'NATIONAL_ON_HAND_STOCK',
	BY_LOCATION_ON_HAND_STOCK: 'BY_LOCATION_ON_HAND_STOCK',
	FLEET_INTELLIGENCE: 'FLEET_INTELLIGENCE',
	VEHICLE_CAPACITY_UTILIZATION: 'VEHICLE_CAPACITY_UTILIZATION',
	TRIPS_PER_VEHICLE: 'TRIPS_PER_VEHICLE',
	TRIPS_SUMMARY: 'TRIPS_SUMMARY',
	OPERATING_TIME_PER_VEHICLE: 'OPERATING_TIME_PER_VEHICLE',
	OPERATING_TIME_SUMMARY: 'OPERATING_TIME_SUMMARY',
	DISTANCE_PER_VEHICLE: 'DISTANCE_PER_VEHICLE',
	IDLE_TIME_SUMMARY: 'IDLE_TIME_SUMMARY',
	REFUEL_HISTORY: 'REFUEL_HISTORY',
	TRIP_ALLOWANCE: 'TRIP_ALLOWANCE',
	BY_PRODUCT_STOCK_SUMMARY: 'BY_PRODUCT_STOCK_SUMMARY',
	ORDERS_PAID_AND_DUE_AMOUNT_REPORT: 'ORDERS_PAID_AND_DUE_AMOUNT_REPORT',
	SALES_REPORT: 'SALES_REPORT',
	SALES_REPORT_BY_SHIPPING: 'SALES_REPORT_BY_SHIPPING',
	SALES_REPORT_BY_DELIVERED: 'SALES_REPORT_BY_DELIVERED',
	TOP_100_REPEAT_CUSTOMERS: 'TOP_100_REPEAT_CUSTOMERS',
	SALES_BY_PRODUCT: 'SALES_BY_PRODUCT',
	SALES_BY_PRODUCT_VARIANT: 'SALES_BY_PRODUCT_VARIANT',
	EMPLOYEE_PERFORMANCE_REPORTS: 'EMPLOYEE_PERFORMANCE_REPORTS',
	DELIVERY_PARTNERS_SALES_REPORT: 'DELIVERY_PARTNERS_SALES_REPORT',
	PAKISTAN_POST_COURIER_CLAIM_REPORT: 'PAKISTAN_POST_COURIER_CLAIM_REPORT',
	EXPENSES_AND_MARGIN: 'EXPENSES_AND_MARGIN',
	STOCK_SUMMARY: 'STOCK_SUMMARY',
	EMPLOYEE_PERFORMANCE_BY_ORDER: 'EMPLOYEE_PERFORMANCE_BY_ORDER',
	EMPLOYEE_PERFORMANCE_BY_ORDER_APPROVAL: 'EMPLOYEE_PERFORMANCE_BY_ORDER_APPROVAL',
	EMPLOYEE_PERFORMANCE_BY_PRODUCT: 'EMPLOYEE_PERFORMANCE_BY_PRODUCT',
	EMPLOYEE_PERFORMANCE_BY_ORDER_STATUS_CHANGE: 'EMPLOYEE_PERFORMANCE_BY_ORDER_STATUS_CHANGE',
	EMPLOYEE_PERFORMANCE_BY_UPSELL_PRODUCT: 'EMPLOYEE_PERFORMANCE_BY_UPSELL_PRODUCT',
	DAILY_STOCK_SHORTAGE: 'DAILY_STOCK_SHORTAGE',
	SALES_SOURCE_BY_PRODUCT: 'SALES_SOURCE_BY_PRODUCT',
	PRODUCT_SALES_BY_CUSTOMERS: 'PRODUCT_SALES_BY_CUSTOMERS',
	ORDER_REPORTS: 'ORDER_REPORTS',
	PRODUCT_REPORTS: 'PRODUCT_REPORTS',
	INVENTORY_REPORTS: 'INVENTORY_REPORTS',
	CUSTOMER_REPORTS: 'CUSTOMER_REPORTS',
	DELIVERY_PARTNER_REPORTS: 'DELIVERY_PARTNER_REPORTS',
	EXPENSE_REPORTS: 'EXPENSE_REPORTS'
}

export const getFormattedReportType = (type) => {
	switch (type) {
		case ReportTypes.ORDER_FULFILLMENT:
			return 'Order Fulfillment'
		case ReportTypes.PENDING_ORDERS:
			return 'Pending Orders'
		case ReportTypes.NATIONAL_ON_HAND_STOCK:
			return 'National - On Hand Stock'
		case ReportTypes.BY_LOCATION_ON_HAND_STOCK:
			return 'By Location - On Hand Stock'
		case ReportTypes.TRACEABILITY:
			return 'Traceability'
		case ReportTypes.FLEET_INTELLIGENCE:
			return 'Fleet Intelligence'
		case ReportTypes.TRIPS_PER_VEHICLE:
			return 'Trips Per Vehicle'
		case ReportTypes.TRIPS_SUMMARY:
			return 'Trips Summary'
		case ReportTypes.OPERATING_TIME_PER_VEHICLE:
			return 'Operating Time Per Vehicle'
		case ReportTypes.OPERATING_TIME_SUMMARY:
			return 'Operating Time Summary'
		case ReportTypes.DISTANCE_PER_VEHICLE:
			return 'Distance Per Vehicle'
		case ReportTypes.IDLE_TIME_SUMMARY:
			return 'Idle Time Summary'
		case ReportTypes.REFUEL_HISTORY:
			return 'Refuel History'
		case ReportTypes.TRIP_ALLOWANCE:
			return 'Trip Allowance'
		case ReportTypes.VEHICLE_CAPACITY_UTILIZATION:
			return 'Vehicle Capacity Utilization'
		case ReportTypes.BY_PRODUCT_STOCK_SUMMARY:
			return 'By Product Stock Summary'
		case ReportTypes.SALES_REPORT:
			return 'Sales Report'
		case ReportTypes.ORDERS_PAID_AND_DUE_AMOUNT_REPORT:
			return 'Orders Paid and Due Amount Report'
		case ReportTypes.SALES_REPORT_BY_SHIPPING:
			return 'Sales Report By Shipping'
		case ReportTypes.SALES_REPORT_BY_DELIVERED:
			return 'Sales Report By Delivered'
		case ReportTypes.TOP_100_REPEAT_CUSTOMERS:
			return 'Top 100 Repeat Customers'
		case ReportTypes.SALES_BY_PRODUCT:
			return 'Sales By Base Product'
		case ReportTypes.SALES_BY_PRODUCT_VARIANT:
			return 'Sales By Product Variant'
		case ReportTypes.EMPLOYEE_PERFORMANCE_REPORTS:
			return 'Employee Performance Reports'
		case ReportTypes.DELIVERY_PARTNERS_SALES_REPORT:
			return 'Delivery Partners\' Sales Report'
		case ReportTypes.PAKISTAN_POST_COURIER_CLAIM_REPORT:
			return 'Pakistan Post Courier Claim Report'
		case ReportTypes.EXPENSES_AND_MARGIN:
			return 'Expenses and Margin'
		case ReportTypes.EMPLOYEE_PERFORMANCE_BY_ORDER:
			return 'Employee Performance Report By Orders'
		case ReportTypes.EMPLOYEE_PERFORMANCE_BY_PRODUCT:
			return 'Employee Performance Report By Products'
		case ReportTypes.EMPLOYEE_PERFORMANCE_BY_ORDER_APPROVAL:
			return 'Employee Performance Report By Order Approvals'
		case ReportTypes.EMPLOYEE_PERFORMANCE_BY_ORDER_STATUS_CHANGE:
			return 'Employee Performance Report By Order Status Change'
		case ReportTypes.DAILY_STOCK_SHORTAGE:
			return 'Daily Stock Shortage'
		case ReportTypes.SALES_SOURCE_BY_PRODUCT:
			return 'Daily Product-wise Sales Report based on Order Source and Order Status'
		case ReportTypes.PRODUCT_SALES_BY_CUSTOMERS:
			return 'Product Sales to Customers'
		case ReportTypes.EMPLOYEE_PERFORMANCE_BY_UPSELL_PRODUCT:
			return 'Employee Performance Report by Upsell Product'
		case ReportTypes.ORDER_REPORTS:
			return 'Order Reports'
		case ReportTypes.PRODUCT_REPORTS:
			return 'Product Reports'
		case ReportTypes.INVENTORY_REPORTS:
			return 'Inventory Reports'
		case ReportTypes.CUSTOMER_REPORTS:
			return 'Customer Reports'
		case ReportTypes.DELIVERY_PARTNER_REPORTS:
			return 'Delivery Partner Reports'
		case ReportTypes.EXPENSE_REPORTS:
			return 'Expense Reports'
		default:
			return capitalizeWord(convertEnumToString(type))
	}
}

export const CustomerSortOption = {
	FREQUENCY: 'frequency',
	VALUE: 'value'
}

export const generalReportTypeOptions = [
	{
		label: getFormattedReportType(ReportTypes.ORDER_FULFILLMENT),
		value: ReportTypes.ORDER_FULFILLMENT
	},
	{
		label: getFormattedReportType(ReportTypes.PENDING_ORDERS),
		value: ReportTypes.PENDING_ORDERS
	},
	{
		label: getFormattedReportType(ReportTypes.TRACEABILITY),
		value: ReportTypes.TRACEABILITY
	},
	{
		label: getFormattedReportType(ReportTypes.NATIONAL_ON_HAND_STOCK),
		value: ReportTypes.NATIONAL_ON_HAND_STOCK
	},
	{
		label: getFormattedReportType(ReportTypes.BY_LOCATION_ON_HAND_STOCK),
		value: ReportTypes.BY_LOCATION_ON_HAND_STOCK
	},
	{
		label: getFormattedReportType(ReportTypes.FLEET_INTELLIGENCE),
		value: ReportTypes.FLEET_INTELLIGENCE,
		children: [
			{
				label: getFormattedReportType(ReportTypes.VEHICLE_CAPACITY_UTILIZATION),
				value: ReportTypes.VEHICLE_CAPACITY_UTILIZATION
			},
			{
				label: getFormattedReportType(ReportTypes.TRIPS_PER_VEHICLE),
				value: ReportTypes.TRIPS_PER_VEHICLE
			},
			{
				label: getFormattedReportType(ReportTypes.TRIPS_SUMMARY),
				value: ReportTypes.TRIPS_SUMMARY
			},
			{
				label: getFormattedReportType(ReportTypes.OPERATING_TIME_PER_VEHICLE),
				value: ReportTypes.OPERATING_TIME_PER_VEHICLE
			},
			{
				label: getFormattedReportType(ReportTypes.OPERATING_TIME_SUMMARY),
				value: ReportTypes.OPERATING_TIME_SUMMARY
			},
			{
				label: getFormattedReportType(ReportTypes.DISTANCE_PER_VEHICLE),
				value: ReportTypes.DISTANCE_PER_VEHICLE
			},
			{
				label: getFormattedReportType(ReportTypes.IDLE_TIME_SUMMARY),
				value: ReportTypes.IDLE_TIME_SUMMARY
			},
			{
				label: getFormattedReportType(ReportTypes.REFUEL_HISTORY),
				value: ReportTypes.REFUEL_HISTORY
			},
			{
				label: getFormattedReportType(ReportTypes.TRIP_ALLOWANCE),
				value: ReportTypes.TRIP_ALLOWANCE
			}
		]
	}
]

export const eCommerceReportTypeOptions = [
	{
		label: getFormattedReportType(ReportTypes.EMPLOYEE_PERFORMANCE_REPORTS),
		value: ReportTypes.EMPLOYEE_PERFORMANCE_REPORTS,
		children: [
			{
				label: getFormattedReportType(ReportTypes.EMPLOYEE_PERFORMANCE_BY_PRODUCT),
				value: ReportTypes.EMPLOYEE_PERFORMANCE_BY_PRODUCT
			},
			{
				label: getFormattedReportType(ReportTypes.EMPLOYEE_PERFORMANCE_BY_ORDER),
				value: ReportTypes.EMPLOYEE_PERFORMANCE_BY_ORDER
			},
			{
				label: getFormattedReportType(ReportTypes.EMPLOYEE_PERFORMANCE_BY_ORDER_APPROVAL),
				value: ReportTypes.EMPLOYEE_PERFORMANCE_BY_ORDER_APPROVAL
			},
			{
				label: getFormattedReportType(ReportTypes.EMPLOYEE_PERFORMANCE_BY_ORDER_STATUS_CHANGE),
				value: ReportTypes.EMPLOYEE_PERFORMANCE_BY_ORDER_STATUS_CHANGE
			},
			{
				label: getFormattedReportType(ReportTypes.EMPLOYEE_PERFORMANCE_BY_UPSELL_PRODUCT),
				value: ReportTypes.EMPLOYEE_PERFORMANCE_BY_UPSELL_PRODUCT
			}
		]
	},
	{
		label: getFormattedReportType(ReportTypes.ORDER_REPORTS),
		value: ReportTypes.ORDER_REPORTS,
		children: [
			{
				label: getFormattedReportType(ReportTypes.ORDERS_PAID_AND_DUE_AMOUNT_REPORT),
				value: ReportTypes.ORDERS_PAID_AND_DUE_AMOUNT_REPORT
			},
			{
				label: getFormattedReportType(ReportTypes.SALES_REPORT),
				value: ReportTypes.SALES_REPORT
			}
		]
	},
	{
		label: getFormattedReportType(ReportTypes.PRODUCT_REPORTS),
		value: ReportTypes.PRODUCT_REPORTS,
		children: [
			{
				label: getFormattedReportType(ReportTypes.SALES_BY_PRODUCT),
				value: ReportTypes.SALES_BY_PRODUCT
			},
			{
				label: getFormattedReportType(ReportTypes.SALES_BY_PRODUCT_VARIANT),
				value: ReportTypes.SALES_BY_PRODUCT_VARIANT
			},
			{
				label: getFormattedReportType(ReportTypes.SALES_SOURCE_BY_PRODUCT),
				value: ReportTypes.SALES_SOURCE_BY_PRODUCT
			}
		]
	},
	{
		label: getFormattedReportType(ReportTypes.INVENTORY_REPORTS),
		value: ReportTypes.INVENTORY_REPORTS,
		children: [
			{
				label: getFormattedReportType(ReportTypes.BY_PRODUCT_STOCK_SUMMARY),
				value: ReportTypes.BY_PRODUCT_STOCK_SUMMARY
			},
			{
				label: getFormattedReportType(ReportTypes.DAILY_STOCK_SHORTAGE),
				value: ReportTypes.DAILY_STOCK_SHORTAGE
			}
		]
	},
	{
		label: getFormattedReportType(ReportTypes.CUSTOMER_REPORTS),
		value: ReportTypes.CUSTOMER_REPORTS,
		children: [
			{
				label: getFormattedReportType(ReportTypes.TOP_100_REPEAT_CUSTOMERS),
				value: ReportTypes.TOP_100_REPEAT_CUSTOMERS
			},
			{
				label: getFormattedReportType(ReportTypes.PRODUCT_SALES_BY_CUSTOMERS),
				value: ReportTypes.PRODUCT_SALES_BY_CUSTOMERS
			}
		]
	},
	{
		label: getFormattedReportType(ReportTypes.DELIVERY_PARTNER_REPORTS),
		value: ReportTypes.DELIVERY_PARTNER_REPORTS,
		children: [
			{
				label: getFormattedReportType(ReportTypes.DELIVERY_PARTNERS_SALES_REPORT),
				value: ReportTypes.DELIVERY_PARTNERS_SALES_REPORT
			}
		]
	},
	{
		label: getFormattedReportType(ReportTypes.EXPENSE_REPORTS),
		value: ReportTypes.EXPENSE_REPORTS,
		children: [
			{
				label: getFormattedReportType(ReportTypes.EXPENSES_AND_MARGIN),
				value: ReportTypes.EXPENSES_AND_MARGIN
			}
		]
	}
]
